.due-date-checkbox {
    cursor: pointer;
    width: 2.5rem !important;
    height: 1.5rem;
}

.input-disabled{
    cursor: no-drop
}

.add-signer-btn {
    padding: 4px 10px;
    font-size: 13px;
    border-radius: 8px;
    color: #fff;
    border: none;
    width: 3.8rem;
    display: flex;
    justify-content: center;
}

.signer-delete-icon{
    color: red;
    font-size: 18px;
    display: flex;
    padding-left: 13px;
    height: 12px;
    align-items: center;
    cursor: pointer;
}

.custom-close-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 0.5rem 1rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #000; /* Change to your desired color */
    transition: color 0.2s;
  }
  
  .custom-close-button:hover {
    color: #ff0000; /* Change to your desired hover color */
  }
  