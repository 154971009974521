.login-body {
  /* background-image: url('/src/assets/login-bg.jpg'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 95vh !important;
}

.dastkhat-logo {
  padding-left: 3rem !important;
  margin-left: 3rem !important;
}

.dastkhat-logo img {
  width: 6rem !important;
  height: 5rem;
  cursor: auto;
}

.sign-vector-section {
  display: flex;
  justify-content: space-evenly;
}

.sign-vector img {
  height: 20vw;
  width: 100%;
  margin-top: 4vw;
}

.form-inputs {
  padding-top: 40px !important;
}

.form-input-field {
  padding: 8px !important;
  border-radius: 10px;
  border: 1px solid grey;
  width: 100%;
  outline: none;
}

.form-input-field input {
  width: 100%;
  border: none;
  outline: none;
}

.virtical-line {
  border-left: 1px solid rgba(88, 88, 88, 0.525);
  /* height: 40px !important; */
  margin-left: 20px;
}

.btnlogin {
  /* background-color: #092636; */
  padding: 7px !important;
  color: rgba(255, 255, 255, 0.81);
  border: none;
  font-size: 15px;
  border-radius: 15px;
  width: 100% !important;
}

.ar-btnlogin {
  padding: 7px !important;
  color: rgba(255, 255, 255, 0.81);
  border: none;
  font-size: 15px;
  border-radius: 15px;
  width: 100% !important;
}

.login-forgetpassword {
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem !important;
  margin-bottom: 3rem !important;
}

.ar-login-forgetpassword {
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.login-form {
  background-color: rgb(255, 255, 255) !important;
  width: 27%;
  padding: 56px 46px !important;
  border-radius: 10px;
}

.footer {
  /* margin-top: 13vh; */
  margin-right: 5rem;
}

.forget-paswd {
  color: rgb(253, 48, 48) !important;
  font-size: 15px;
  text-decoration: none;
}

.dasthkath-logo-login {
  position: absolute;
  bottom: 59px;
  left: 16vw;
  height: 6vw;
}

.onoffswitch {
  position: relative;
  width: 80px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
  width: 70% !important;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
  color: #002f48;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  /* height: 23px; */
  padding: 0;
  line-height: 25px;
  font-size: 12px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  box-sizing: border-box;
  font-weight: 600;
  background-color: rgb(224, 224, 224);
}

.onoffswitch-inner:before {
  content: "العربية" !important;
  padding-left: 10px;
  color: black;
  bottom: 10px !important
    /* background-color: green; color: #FFFFFF; */
}

.onoffswitch-inner:after {
  content: "English";
  padding-right: 10px;
  /* background-color: red; color: #FFFFFF; */
  text-align: right;
  color: black;
}

.onoffswitch-switch {
  display: block;
  width: 20px;
  margin: 2px;
  background: #FFFFFF;
  position: absolute;
  top: 0;
  bottom: 0;
  /* right: 101px; */
  border: 1px solid #ffffff;
  border-radius: 50%;
  transition: all 0.3s ease-in 0s;
  background-color: #004367;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.or-label {
  margin: 20px;
  display: flex;
  color: gray;
  align-items: center;
}

.or-label .text {
  padding: 0px 10px;
}

.or-label span:first-child {
  width: 50%;
  border-top: 1px solid;
  display: inline-block;
}
.or-label span:last-child {
  width: 50%;
  border-top: 1px solid;
  display: inline-block;
}

.google-button {
  position: relative;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border-color: transparent;
  color: gray;
  font-weight: 600;
}

.google-button img {
  position: absolute;
  left: 10px;
  top: 5px;
  height: 34px;
}

@media (max-width: 1280px) {
  .gap {
    gap: 2.5rem !important;
  }

  .dastkhat-logo img {
    width: 5rem !important;
  }

  .sign-vector img {
    height: 26vw !important;
    margin-top: 1vw;
  }

  .ar-login-forgetpassword {
    gap: 0px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .login-forgetpassword {
    gap: 0px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .mt-4 {
    margin-top: 0.5rem !important;
  }

  .respnsive-display {
    display: flex;
    justify-content: flex-end;
  }

  .forget-paswd {
    font-size: 17px;
  }

  .respnsive-login {
    display: flex;
    justify-content: center;
  }

  .btnlogin {
    font-size: 15px;
    border-radius: 15px;
  }

  .login-body {
    height: 94vh !important;
  }

  .login-form {
    width: 40%;
    padding: 40px 46px !important;
  }

  .dasthkath-logo-login {
    bottom: 48px;
    left: 13vw;
    width: 9vw;
    height: 9.5vw;
  }

  /* .sign-vector-section {
    margin-top: 1rem;
  } */
  /* .sign-vector img {
    height: 24vw !important;
  } */
}

@media screen and (min-width: 820px) and (max-width: 912px) {
  .sign-vector img {
    height: 35vw !important;
  }

  .dasthkath-logo-login {
    bottom: 90px !important;
    left: 6vw !important;
    height: 20vw !important;
    width: 10rem !important;
  }

  .sign-vector-section {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

  /* surface pro 7 , ipad mini , ipad air */
  .login-form {
    width: 35%;
    padding: 56px 46px !important;
    border-radius: 10px;
  }

  .login-body {
    background-size: 100% 101%;
  }

  .dasthkath-logo-login {
    bottom: 74px;
    left: 10vw;
    height: 20vw;
    width: 9rem;
  }

  .login-form {
    width: 31rem;
    padding: 46px 42px !important;
    height: 24rem !important;
  }

  .sign-vector-section {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .sign-vector img {
    height: 28vw !important;
    margin-top: 2vw !important;
  }

  .ar-login-forgetpassword {
    gap: 0px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .login-forgetpassword {
    gap: 15px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .mt-4 {
    margin-top: 1.5rem !important;
  }

  .respnsive-display {
    display: flex;
    justify-content: flex-end;
  }

  .forget-paswd {
    font-size: 17px;
  }

  .respnsive-login {
    display: flex;
    justify-content: center;
    margin-top: -1rem;
  }

  .btnlogin {
    font-size: 15px;
    border-radius: 15px;
    width: 50% !important;
  }

  .ar-btnlogin {
    border-radius: 30px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .sign-vector-section {
    margin-top: 0rem;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .login-form {
    width: 24rem;
    padding: 26px 42px !important;
    height: 17.5rem !important;
  }

  .gap {
    gap: 2rem;
  }

  .login-forgetpassword {
    gap: 1px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .mt-4 {
    margin-top: 0.5rem !important;
  }

  .respnsive-display {
    display: flex;
    justify-content: flex-end;
  }

  .forget-paswd {
    font-size: 17px;
  }

  .respnsive-login {
    display: flex;
    justify-content: center;
    margin-top: -1rem;
  }

  .btnlogin {
    font-size: 15px;
    border-radius: 15px;
    width: 50% !important;
  }

  .sign-vector img {
    height: 28vw !important;
    width: 100%;
    margin-top: -3vw;
  }

  .dastkhat-logo {
    padding-left: 0rem !important;
    margin-left: 1.5rem !important;
  }

  .dasthkath-logo-login {
    position: absolute;
    bottom: 68px;
    left: 8vw;
    height: 18vw;
    width: 15vw;
  }

  .dastkhat-logo img {
    width: 3rem !important;
    height: 3rem;
    cursor: auto;
  }

  .form-inputs {
    padding-top: 9px !important;
  }


  .ar-login-forgetpassword {
    gap: 0px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .ar-btnlogin {
    border-radius: 30px;
  }
}

@media screen and (min-width:320px) and (max-width:480px) {

  /* samsung galaxy A51/71 , samsung galaxy s20 ultra , 
    samsung galaxy s8+ , pixel 5 , iphone 12 pro , Iphone xr*/
  .sign-vector-section {
    /* margin-top: calc(-1*1vh); */
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .login-body {
    height: 96vh !important;
    background-size: 100% 101%;
  }

  .login-form {
    width: 20.5rem;
    padding: 16px 40px !important;
    height: 16.5rem !important;
  }

  .sign-vector img {
    height: 30vw !important;
    margin-top: 5vw;
  }

  .dastkhat-logo {
    padding-left: 0rem !important;
    margin-left: 1.5rem !important;
  }

  .dasthkath-logo-login {
    bottom: 56px;
    left: 6vw;
    height: 20vw;
    width: 20vw;
  }

  .dastkhat-logo img {
    width: 3rem !important;
    height: 3rem;
  }

  .form-inputs {
    padding-top: 14px !important;
  }

  .login-forgetpassword {
    gap: 1px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .ar-login-forgetpassword {
    gap: 0px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .mt-4 {
    margin-top: 0.5rem !important;
  }

  .respnsive-display {
    display: flex;
    justify-content: flex-end;
  }

  .forget-paswd {
    font-size: 17px;
  }

  .respnsive-login {
    display: flex;
    justify-content: center;
    margin-top: -1rem;

  }

  .btnlogin {
    font-size: 15px;
    border-radius: 15px;
    width: 50% !important;
  }

  .ar-btnlogin {
    border-radius: 30px;
  }
}

@media screen and (min-width:275px) and (max-width:300px) {

  /* galaxy fold */
  .sign-vector-section {
    margin-top: 0rem;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .login-form {
    width: 15rem;
    padding: 11px 22px !important;
    height: 14.5rem !important;
  }

  .sign-vector img {
    height: 43vw !important;
    margin-top: 1vw;
  }

  .dasthkath-logo-login {
    bottom: 44px;
    left: 8vw;
    height: 34vw;
    width: 20vw;
  }

  .dastkhat-logo {
    padding-left: 0rem !important;
    margin-left: 1.5rem !important;
  }

  .dastkhat-logo img {
    width: 3rem !important;
    height: 3rem;
    margin-top: 2rem !important;
  }

  .form-inputs {
    padding-top: 6px !important;
  }

  .login-forgetpassword {
    gap: 1px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .ar-login-forgetpassword {
    gap: 0px;
    flex-direction: column;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    align-items: unset;
  }

  .mt-4 {
    margin-top: 0.5rem !important;
  }

  .respnsive-display {
    display: flex;
    justify-content: flex-end;
  }

  .forget-paswd {
    font-size: 17px;
  }

  .respnsive-login {
    display: flex;
    justify-content: center;
    margin-top: -1rem;
  }

  .btnlogin {
    font-size: 15px;
    border-radius: 15px;
    width: 50% !important;
  }

  .forget-paswd {
    font-size: 12px;
  }

  .ar-btnlogin {
    border-radius: 30px;
  }
}