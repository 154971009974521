.otp-field {
  border-radius: 10px;
  outline: none;
  padding: 8px !important;
  border: 1px solid #b6b6b6;
  text-align: center;
  font-weight: bold;
}

.otp-field:not(:last-child) {
  margin-right: 15px;
}

.login-form {
  height: 10%;
}

.bgWhite {
  background: white;
  box-shadow: 0px 3px 6px 0px #cacaca;
}

.title {
  font-weight: 600;
  margin-top: 20px;
  font-size: 24px
}

/* yerun */

form input {
  display: inline-block;
  width: 50px;
  height: 40px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid grey;
}

/* done */

.forgot-pass-footer {
  margin-top: 14.3rem;
}

@media (max-width: 1280px) {
  .btnlogin {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 1463px) and (min-width: 1255px) {
  .login-form {
    height: 23rem;
  }
}

@media screen and (max-width: 1255px) and (min-width: 930px) {
  .login-form {
    height: 21rem;
  }
  
}