.doc-preview-container {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.react-pdf__Document>div {
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
}

.react-pdf__Page__canvas {
    /* cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="60"><rect x="0" y="0" width="100" height="100" fill="rgb(63, 15, 151)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="12" fill="white">SIGNATURE</text></svg>'), auto; */
    opacity: 1;
    border: 3px solid #CCCCCC;
    width: 730px !important;
}

.emailSent .react-pdf__Page__canvas {
    cursor: context-menu !important;
}

.specialStyleSignature {
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="60"><rect x="0" y="0" width="100" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="15" fill="white">SIGNATURE</text></svg>'), auto;
}

.specialStylePhoto {
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect x="5" y="0" width="100" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="14" fill="white">PHOTO</text></svg>'), auto;
}

.specialStyleStamp {
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"><rect x="0" y="0" width="22rem" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="14" fill="white">STAMP</text></svg>'), auto;
}

.specialStyleText {
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="60"><rect x="0" y="0" width="22rem" height="100" fill="rgb(0 93 135)" opacity="0.4" /><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="14" fill="white">TEXTBOX</text></svg>'), auto;
}

.StampPhotoSignIcons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid;
    padding: 8px;
    border-radius: 10px;
    border: none;
    height: 6rem;
    width: 5.8rem;
    background-color: #d0dde9;
}

.StampPhotoSignIconsdoc {
    display: flex;
    align-items: center;
    border: 2px solid;
    padding: 2px;
    border-radius: 10px;
    border: none;
    height: 4rem;
    width: 4rem;
    margin-bottom: 0px;
    background-color: #d0dde9;
}

.StampPhotoSignIcons:hover {
    cursor: pointer;
}

.hide-canvas {
    display: none;
}

.annotation-text {
    text-align: center;
    font-weight: 700;
    overflow: hidden;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.close-button:hover {
    background-color: red,
}

.pdf-preview {
    /* position: relative; */
    background: #F8F8F8 0% 0% no-repeat padding-box;
}

.pdf-details {
    position: sticky;
    top: 0;
    width: 100%;
    padding-top: 2rem;
}

.recipients-title {
    color: #D34651;
    line-height: 2.2rem;
}

.signers-mail {
    /* word-wrap: break-word; */
    /* width: 15rem; */
    font: initial;
    /* text-align: left; */
    /* letter-spacing: 0px; */
    color: #2E2E2E;
    opacity: 1;
    border: 1px solid #FDE9A7;
    border-radius: 6px;
    /* width: -moz-fit-content; */
    /* width: fit-content; */
    font-size: normal;
    font-size: 15px;
    padding: 11px 14px;
}

.signers-title {
    margin-top: 1rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    font-size: 18px;
    padding-bottom: 15px;
    border-bottom: 1px solid #c4b6b6;
    width: 67px;
    display: flex;
    flex-wrap: wrap;
}

.recipient-text {
    color: #3F6ABC;
    cursor: pointer;
}

.recipients {
    text-align: left;
    font-family: Urbanist;
    font-size: 16px;
    letter-spacing: 0px;
    opacity: 1;
}

.section {
    line-height: 0.5rem;
    padding: 0.5rem;
}

.pdf-name {
    line-height: 1.5rem;
}

.close-button-container:hover {
    background-color: red !important;
}

.zoominout-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.circle {
    position: relative;
    display: inline-block;
}

.circle::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: -1;
    background: #D24625 0% 0% no-repeat padding-box;
    opacity: 0.12;
}

.pdf-name-container {
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 0.51;
}

.finish-button-container {
    text-decoration: none;
}

.signer-not-found {
    display: flex;
    justify-content: flex-start;
    flex-direction: inherit;
    gap: 5px;
}

.document-update {
    zoom: 1;
}

.doc-zoom-1-stage {
    zoom: 1;
}

.doc-zoom-2-stage {
    zoom: 1.2;
}

.doc-zoom-3-stage {
    zoom: 1.3;
}

.doc-zoom-4-stage {
    zoom: 1.4;
}

.doc-zoom-5-stage {
    zoom: 1.5;
}

.doc-zoom-6-stage {
    zoom: 1.6;
}

.doc-zoom-7-stage {
    zoom: 1.7;
}

/* .recipient-email{
    overflow-wrap:
} */
.Textwrap {
    overflow-wrap: anywhere;
}