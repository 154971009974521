.custom-card {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 1.3rem;
}

.documenttabls .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-accent-bg: #e5f4f6;
    color: black;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); */
}
.documenttabls .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #ffffff;
    color: black;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); */
}
.documenttabls .table {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); 
}

.documenttabls .table td {
    color: rgb(66, 54, 54);
    /* padding: 10px; */
    text-align: start;
    padding-left: auto;
    border:none !important;
}
.documenttabls .table tr {

    border:none !important;
}
.draftimgae {
    height: 3rem;
    position: relative;
    width: 9rem;
    top: 3.45rem;
    right: 1.3rem;
}


.text-overlay {
    position: relative;
    top: 4.9rem;
    right: 6rem;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500; 
    visibility: visible;
     text-align: center; 
}

.myDocumentsImage {
    top: 0.45rem;
    right: 1.8rem;
    width: 13rem;
}

.my-documents-text-overlay {
    top: -1.6rem;
    right: -5rem;
}

.documents-border {
    border-style: dashed;
    border-color: #DEDEDE;
    border-width: 0 0.1rem 0 0;
}

.admin-dashboard-documents thead th{
    padding-top: 3rem;
    text-align: center;
    padding-left: 2rem;
}

.no-documents-message {
    font-size: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    font-size: 20px;
}