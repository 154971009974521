.payment-history-border {
    border-bottom: 1px solid rgba(158, 158, 158, 0.6);
}

.download-icon {
    width: 1rem;
}

.plus-icon {
    width: 0.8rem;
}

.primary {
    border: 1px solid rgb(32, 104, 136);
    font-weight: 900;
    font-size: 10px;
    color: rgb(32, 104, 136);
    padding: 5px;
    border-radius: 3px;
    margin-top: calc(-1*1vh);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 7px;
    height: 28px;
}

.cardImages {
    background-color: #fff;
    padding: 0px 19px 0px 19px;
    width: 6.5rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.res-height{
    height: 4rem;
}
/* .delete-btn{
    height: 1.5rem;
} */

.details-card {
    gap: 3rem!important;
}
.edit-buton{
    font-size: 13px;
}

.form-check-input[type=checkbox] {
    border-radius: 1.25em;
}
.form-check-input:checked {
    background-color: rgb(32, 104, 136);
    border-color: rgb(32, 104, 136);
}
.form-floating>label {
    color: rgb(32, 104, 136);
}
.payment-icons{
    position: absolute;
    right: 1rem;
    top: 25%;
    height: 1.5rem;
}
.payment-card-icons{
    position: absolute;
    right: 1rem;
    top: 15%;
    height: 2.5rem;
    width: 3rem;
}
.modal-title {
    color: rgb(32, 104, 136);
    font-weight: 600;
}

.pl-23{
    padding-left: 23px !important;
}

.search-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
  }
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(32, 104, 136);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgb(32, 104, 136);
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (max-width: 767px) {
    .cardImages {
        height: 4rem ;
        width: 4.5rem ;
        
    }

    .details-card {
        gap: 0.5rem !important;
        align-items: flex-end !important;
    }

    .card-section {
        gap: 0.5rem !important;
        padding: 1rem !important;

    }

    .card-details {
        flex-direction: column-reverse !important;
        gap: 0rem !important;
    }
    .primary {
        bottom: 0px;
    }

    /* .p-4 {
        padding: 1rem !important;
    } */
}

@media (max-width: 575px) {
    .cardImages {
        height: 5rem ;
        width: 7rem ;
    }
    .delete-btn{
        height: 2rem;
    }
    .primary {
        font-size: 10px;
    }
    .edit-buton{
        font-size: 22px;
    }
    .res-height{
        height: 6rem;
    }
    .font-styles {
        font-size: 22px;
    }

    .fontStyle {
        font-size: 34px;
    }

    .details-card {
        gap: 2rem !important;
        align-items: flex-end !important;
    }

    .card-section {
        gap: 0.5rem !important;
        padding: 1rem !important;

    }

    .card-details {
        flex-direction: column-reverse !important;
        gap: 0rem !important;
    }

    /* .p-4 {
        padding: 1rem !important;
    } */
}

/* TRY */
@media (min-width: 498px) and (max-width: 547px) {
    .cardImages {
        height: 5rem;
        width: 5.8rem;
    }
    .details-card {
        gap: 2rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 29px;
    }
    .res-height {
        height: 5rem;
    }
    .edit-buton {
        font-size: 13px;
    }
}
@media (min-width: 481px) and (max-width: 497px) {
    .cardImages {
        height: 5rem;
        width: 5.8rem;
    }
    .details-card {
        gap: 2rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 27px;
    }
    .res-height {
        height: 5rem;
    }
    .edit-buton {
        font-size: 12px;
    }
}
@media (min-width: 460px) and (max-width: 480px) {
  
    /* CSS */
    /* .cardImages {
        height: 5rem;
        width: 6rem;
    } */
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 28px;
    }
    .cardImages {
        height: 5rem;
        width: 5rem;
    }
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .res-height {
        height: 4.5rem;
    }
    .edit-buton {
        font-size: 12px;
    }
  }

@media (min-width: 440px) and (max-width: 459px) {
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 27px;
    }
    .cardImages {
        height: 5rem;
        width: 4.8rem;
    }
    .details-card {
        gap: 0.8rem !important;
        align-items: flex-end !important;
    }
    .res-height {
        height: 4rem;
    }
    .edit-buton {
        font-size: 12px;
    }
}
@media (min-width: 420px) and (max-width: 439px) {
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 24px;
    }
    .font-styles {
        font-size: 16px;
    }
    .cardImages {
        height: 3.8rem;
        width: 4.8rem;
    }
    .res-height {
        height: 3rem;
    }
    .edit-buton {
        font-size: 12px;
    }
}
@media (min-width: 400px) and (max-width: 419px) {
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 24px;
    }
    .font-styles {
        font-size: 18px;
    }
    .cardImages {
        height: 3.8rem;
        width: 3.4rem;
    }

    .res-height {
        height: 3rem;
    }
    .edit-buton {
        font-size: 12px;
    }
}

@media (min-width: 370px) and (max-width: 399px) {
    .fontStyle {
        font-size: 22px;
    }
    .font-styles {
        font-size: 16px;
    }
    .cardImages {
        height: 3rem;
        width: 3rem;
        margin-bottom: 3%;
    }
    
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .res-height {
        height: 3rem;
    }
    .edit-buton {
        font-size: 12px;
    }
}
@media (min-width: 345px) and (max-width: 369px) {
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 20px;
    }
    .font-styles {
        font-size: 16px;
    }
    .cardImages {
        height: 3rem;
        width: 2.8rem;
        margin-bottom: 2%;
    }
    .res-height {
        height: 2.5rem;
    }
    .edit-buton {
        font-size: 12px;
    }
}
@media (min-width: 320px) and (max-width: 344px) {
    .details-card {
        gap: 0.4rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 18.5px;
    }
    .cardImages {
        height: 3rem;
        width: 2.6rem;
        margin-bottom: 2%;

    }
    .edit-buton {
        font-size: 12px;
    }
    .primary {
        font-size: 9px;
    }
    
    .details-card {
        gap: 0.7rem !important;
        align-items: flex-end !important;
    }
    .res-height {
        height: 2.5rem;
    }
    .font-styles {
        font-size: 16px;
    }
}
@media (min-width: 280px) and (max-width: 319px) {
    .details-card {
        gap: 1rem !important;
        align-items: flex-end !important;
    }
    .fontStyle {
        font-size: 15px;
    }
    .cardImages {
        height: 2.5rem;
    width: 1.4rem;
    margin-bottom: 1%;
    }
    .primary {
        font-size: 9px;
    }
    .edit-buton {
        font-size: 12px;
    }
    
    .details-card {
        gap: 0.3rem !important;
        align-items: flex-end !important;
    }
    .res-height {
        height: 2rem;
    }
    .font-styles {
        font-size: 12px;
    }
}

/* Error */
/* @media (max-width: 546px) {
.fontStyle {
    font-size: 30px;
}
} */
/* @media (max-width: 507px) {
.fontStyle {
    font-size: 27px;
}
} */
/* @media (max-width: 478px) {
.fontStyle {
    font-size: 26px;
}
.details-card {
    gap: 0.9rem !important;
    align-items: flex-end !important;
}
/* .    height: 5rem;
    width: 5rem; */
    /* .cardImages {
        height: 5rem;
    width: 5rem;
    }
    .res-height {
        height: 4rem;
    }
}  */

/* @media (max-width: 381px) {
    .cardImages {
        height: 3rem !important;
        width: 4.5rem !important;
    }
    .delete-btn{
        height: 2rem;
    }
    .primary {
        font-size: 15px;
    }
    .edit-buton{
        font-size: 22px;
    }
    .res-height{
        height: 4rem !important;
    }
    .font-styles {
        font-size: 22px;
    }

    .fontStyle {
        font-size: 34px;
    }

    .details-card {
        gap: 0.6rem !important;
        align-items: flex-end !important;
    }

    .card-section {
        gap: 0.5rem !important
    }

    .card-details {
        flex-direction: column-reverse !important;
        gap: 0rem !important;
    }

    .p-4 {
        padding: 1rem !important;
    } */
    /* .cardImages {
        height: 4rem !important;
        width: 4.5rem !important;
    }

    .details-card {
        gap: 0.5rem !important;
        align-items: flex-end !important;
    }

    .card-section {
        gap: 0.5rem !important
    }

    .card-details {
        flex-direction: column-reverse !important;
        gap: 0rem !important;
    }

    .p-4 {
        padding: 1rem !important;
    } */

    /* .primary-alignmnt{

    } */
    /* .card-actions{
        padding-right: 1rem;
    } */
/* } */

/* @media (max-width: 280px) {
    .cardImages {
        height: 4rem !important;
        width: 4.5rem !important;
    }

    .details-card {
        gap: 0.5rem !important;
        align-items: flex-end !important;
    }

    .card-section {
        gap: 0.5rem !important
    }

    .card-details {
        flex-direction: column-reverse !important;
        gap: 0rem !important;
    }

    .p-4 {
        padding: 1rem !important;
    }
} */
