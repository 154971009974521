.doc-preview-container {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
}

.react-pdf__Document>div {
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
}

.document-sign .react-pdf__Page__canvas {
    cursor: context-menu;
}

.nextPreviousbtn {
    width: 4.8rem;
    height: 2rem;
    background-color: white;
    color: #206888;
    border: 1.5px solid #206888;
    border-radius: 1rem;
}

.actionButtons {
    background-color: white;
    box-shadow: lightpink 2px 2px 4px;
    width: 9rem;
    height: 2rem;
    border-radius: 2rem;
    /* margin-left: 1rem; */
}

.actionButtonText {
    font-size: smaller;
    padding: 0.3rem;
}

/* .recipient-email{
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
} */

.annotation-text {
    text-align: center;
    font-weight: 500;
    overflow-wrap: break-word;
    overflow: hidden;
    font-size: 1rem;
    line-height: 1.75rem;
}

.close-button:hover {
    background-color: red,
}

.pdf-preview {
    background: #F8F8F8 0% 0% no-repeat padding-box;
}

.home-button-container {
    text-decoration: none;
}

.recipients-title {
    color: #D34651;
    line-height: 2.2rem;
}

.recipient-text {
    color: #3F6ABC;
    cursor: pointer;
}

/* .recipients {
    text-align: left;
    font-family: Urbanist;
    font-size: 16px;
    letter-spacing: 0px;
    opacity: 1;
} */

.recipients {
    text-align: left;
    font-family: Urbanist;
    font-size: 11px;
    letter-spacing: 0px;
    opacity: 1;
    margin-right: 10px;
    color: white;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.gap-1rem {
    gap: 1rem;
}

.section {
    line-height: 1.5rem;
    padding: 0.5rem;
}

.pdf-name {
    line-height: 1.5rem;
}

.close-button-container:hover {
    background-color: red !important;
}

.zoominout-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.circle {
    position: relative;
    display: inline-block;
}

.circle::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 47%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 17px;
    border-radius: 50%;
    z-index: -1;
    background: #c5c2c2 0% 0% no-repeat padding-box;
    opacity: 1.5;
}

.pdf-sign-texts {
    list-style: none;
    font-size: 18px;
    font-family: Urbanist;
    font-style: normal;
    color: #14455C;
    opacity: 1;
    letter-spacing: 0px;
    line-height: 2rem;
    user-select: none;
}

.recipient-sign-img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.recipient-stamp-img {
    width: 7rem;
    height: 7rem;
    /* max-width: 60%;
    max-height: 100%; */
}

.react-pdf__message--loading {
    /* width: 50%; */
    width: 40vw !important;
}

.settings-form-container {
    background-color: #F8F8F8;
    border-radius: 28px;
}

.input-label {
    /* color: #1E607E; */
    font-weight: bold;
}


.collapse.show {
    height: auto !important;
    opacity: 1 !important;
    transition: height 2s ease, opacity 2s ease !important;
    /* 1 second transition duration for height and opacity */
}

.name-input {
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    outline: none;
    padding: 8px 15px;
    background: white;
}

.save-btn,
.save-btn:hover {
    background: #092636;
    color: #fff;
}

.lh-one {
    line-height: 1.2rem;
}

.my-uploaded-signature {
    border-radius: 8px;
    /* box-shadow: 0px 8px 6px #00000029; */
    background-color: #fff;
}

.uploadImageLabel {
    background: #092636;
    color: #fff;
    border-radius: 8px;
}

.no-signature {
    color: rgb(231, 79, 79);
}

.close-icon {
    position: absolute;
    right: 18px;
    top: 10px;
    border: none;
    background-color: #fff;
    color: #877c7c;
    font-size: 20px;
}

.signatureCanvas {
    border: 2px dashed rgba(0, 0, 0, 0.645);
    border-radius: 5px;
    max-width: 100%;
}

.zoominout-container {
    min-width: fit-content;
    position: fixed;
    bottom: 3rem;
    width: 15%;
    background-color: #f2f2f2;
    padding: 4px;
    display: flex;
    /* margin-left: 25rem; */
    z-index: 4;
    border-radius: 23px;
    justify-content: center;
    background: black;
    opacity: 87%;
}

.email-sent {
    cursor: not-allowed !important;
    border: none;
}

.email-sent-Sign {
    color: #877c7c !important;
    cursor: not-allowed !important;
}

.email-not-sent {
    cursor: not-allowed;
}

.comment-section {
    max-height: 35rem;
    overflow-y: auto;
}

.not-uload-stamp {
    width: 9.5rem;
    height: 9rem;
}

.rejected-heading {
    color: rgba(255, 2, 2, 0.831);
}

.rejected-status {
    border-bottom: 1px solid black;
}

.recipient-comment {
    background-color: #fff;
    border-radius: 14px;
}

.recipient-name {
    background-color: #1b5a76;
    width: 32px;
    color: #fff;
}

.recipient-email {
    color: #1b5a76;
    max-width: 11rem;
    font-size: x-small;
}

.recipient-reason {
    font-size: 15px;
    color: #707070;
}

.pdf-textarea textarea::placeholder {
    color: red;
}

/* Media Queries */
@media (max-width: 912px) {
    .user-icon {
        width: 2.6vh !important;
        height: 2.6vh !important;
    }
}

@media (max-width: 600px) {
    .otp-title {
        font-size: 20px;
    }

    .user-icon {
        width: 4vh !important;
        height: 4vh !important;
    }
}

@media (max-width: 393px) {
    .otp-title {
        font-size: 18px;
    }
}

@media (max-width: 280px) {
    .otp-title {
        font-size: 14px;
    }
}

@media (max-width: 817px) {
    .reject-finish-btn {
        display: block !important;
    }
}

@media (max-width: 580px) {
    .reject-finish-btn {
        display: flex !important;
    }
}

@media only screen and (min-width: 768px) {
    .document-sign .react-pdf__Page__canvas {
        cursor: context-menu;
        width: 100% !important;
    }
}

@media (max-width: 580px) {
    .nextPreviousbtn {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
    }

    .prev {
        position: fixed;
        bottom: 1rem;
        left: 1rem;
    }
}

.document-sign-previous {
    position: fixed;
    bottom: 3rem;
    left: 2rem
}

.document-sign-next {
    position: fixed;
    bottom: 3rem;
    left: calc(100vw - 30rem)
}

.document-container {
    overflow-y: scroll;
    max-height: 82.5vh;
}

.document-container::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.re-assign-button-container:hover {
    background-color: skyblue !important;
}