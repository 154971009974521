.main_container{
    gap: 3rem;
    width: 81%;
    font-weight: bold;
}

.active-adminPanel-nav-link,
.adminPanel-nav-link:hover {
    border-bottom: 4px solid #0c0c0c;
    color: #0e0e0e;
    font-weight: bold;
}
.adminPanel-nav-link {
    text-decoration: none;
    text-align: center;
    font-weight: bold; 
}

.font-input {
  width: 79% !important;
  min-width: 72%
}

.adminPanel-line{
    border-bottom: 1px solid black;
}

.tab{
font-weight: bold;
}
.user-btn-nav{
    background: #206787;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
}
.btns{
    margin-right: 10rem;
    display: flex;
    padding: 4px;
    gap: 2rem;
}


.email-list{
      border-radius: 0.7rem;
      border: 1px solid;
      justify-content: space-around;
      display: flex;
}

.submitbtn{
    width: 5rem;
    color: white;
    border: none;
    font-size: 14px;
}
.modalbtn{
    width: 4rem;
    background: #206585;
    color: white;
    border: none;
}

.modal-heading{
    /* background-image: url('../../assets/shape.svg'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}
.modalbtn-footer{
    width: 4rem;
    background: #dee2e4;
    padding: 2px;
    color: rgb(8, 8, 8);
    border: none;
    border-radius: 6px;
}

.common-color-st {
    font-weight: 600;
}

.color-selector-whole{
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.language-section{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.customization-section {
    display: flex;
    gap: 7rem;
    align-items: center;
}

.customization-section-lang {
    display: flex;
    gap: 4.3rem;
    align-items: center;
}

.customization-section-font {
  display: flex;
  gap: 7.3rem;
  align-items: center;
}

.appearance-whole{
    border: 1px solid rgba(158, 158, 158, 0.6);
    padding: 20px;
    border-radius: 10px;
}

.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #121212dc;
    font-size: 14px;
    padding: 10px 12px;
    background-color: rgba(221, 221, 221, 0.708);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    width: 9.8rem;
    height: 30px;
  }


.background {
    height: 100%;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  
  .title {
    color: #ffffff;
    font-family: verdana;
    text-align: center;
    margin: 0;
    padding: 30px 0;
    opacity: .9;
  }
  
  .overlay {
    width: 30px;
    height: 30px;
    border-radius: 10%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 105px;
    transition: 1s;
    
    &.active {
      top: calc(-50% + 105px);
      width: 160%;
      height: 160%;
      left: -30%;
    }
  }
  

  .table-row-admin {
    border-top:  1px solid grey;
  }


  #color-picker {
    text-align: center;
    margin-top: 10px;
  }

  .appearance-tab-dropdown {
    width: 8rem;
  }

  .appearance-tab-dropdown .selected-option {
    max-width: 86%;
    overflow: hidden;
  }

  .appearance-tab-dropdown .dropdown-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #212529;
  }

  .appearance-tab-dropdown .dropdown-toggle::after{
    margin-left: auto;
  }

  .appearance-tab-dropdown .dropdown-menu{
    width: 8rem;
    /* width: 100%; */
    min-width: fit-content;
    max-height: 200px;
    overflow: auto;
  }
  
  .wrapper-dropdown {
    position: relative;
    width: 100%;
    background: #fff;
    color: #2e2e2e;
    font-size: 15px;
    outline: none;
    cursor: pointer;
  }
  
  .wrapper-dropdown > span {
    display: flex;
    border: 1px solid #ababab;
    align-items: center;
    padding: 5px 15px 6px 15px;
}
  
  .wrapper-dropdown > span:after {
    content: '';
    top: calc(50% + 4px);
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: #2e2e2e transparent;
    margin-left: 0.3rem;
  }
  
  .wrapper-dropdown .dropdown {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    font-weight: normal;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    border: 1px solid #ababab;
    border-top: 0;
  }
  
  .wrapper-dropdown .dropdown li {
    display: block;
    text-decoration: none;
    color: #2e2e2e;
    padding: 5px;
    cursor: pointer;
    background-color :#ffffff;
  }
  
  .wrapper-dropdown .dropdown li:hover {
    cursor: pointer;
    background-color: #efefef;
  }
  
  .circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10%;
    margin-right: 7px;
  }

  .file-name-logo{
    color:#353535;
    margin-left: 10px;
    font-weight: 700;
  }

  .importUserBtn {
    padding: 0.1rem;
    border-radius: 10px;
  }
  .admin-btn-responsive{
    padding: 2vw;
    display: flex;
    font-size: x-small;
    font-weight: 700;
  }
  .admin-btn-responsive-bg{
    display: flex;
    font-size: x-small;
    font-weight: 700;
    padding: 0.4vw;
  }

  .upload-btn-main {
    display: flex !important;
    gap: 8.4rem !important;
    align-items: center !important;
  }

  .ad_tab {
    margin: 20px 100px;
    border: 1px solid;
    border-radius: 10px;
    padding: 30px 20px;
  }

  .ad_tab h3 {
    color: #206888;
    font-weight: bold;
  }

  .btn-actions {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .btn-actions button {
    padding: 6px 20px;
    border-radius: 10px;
    background-color: #206585;
    border-color: transparent;
    color: white;
  }

  .btn-actions button:hover {
    background-color: #206585;
  }

  .btn-actions .cancel-btn {
    background-color: grey !important;
    color: white;
    margin-right: 5px;
  }

  #file-input {
    display: none;
  }
  #file-input-label{
    border: 1px solid lightgray;
    padding: 0.3rem 1.25rem;
    cursor: pointer;
  }
  
  .logo-name{
      box-shadow: 0px 0px 4px 1px lightgrey;
      border-radius: 5px;
      min-width: 13rem; 
      font-size: small; 
    }
    .gap-7-3-rem {
      gap: 7.3rem;
    } 
  .main-appearance-container{
    overflow-x:hidden;
    overflow-y: auto;
  }  

  /* Icons */

  .appearance-icon,
  .user-icon,
  .subscriptions-icon,
  .payment-history-icon {
    width: 1.3rem;
  }

/* Media Queries */
  @media (max-width: 1024px) {
    .main-appearance-container {
      margin-bottom: 2rem;
    }

    .color-selector-whole {
      display: block;
    }

    .logo-container {
      margin-left: 5rem !important;    
    }
  }

  @media (max-width: 912px) {
    .user-icon {
      height: 1.1rem !important;
      width: 1.1rem !important;
    }
  }

  @media screen and (max-width: 771px) {
    .table-navbar {
      justify-content: center;
      display: flex;
    }

    .user-icon {
      height: 1.1rem !important;
      width: 1.1rem !important;
    }
    .fonts-adjust{
      font-size: 10px;
    }

    .color-selector-whole {
      display: block;
    }

    .logo-container {
      margin-left: 3rem !important;    
    }
  }

  @media screen and (max-width: 450px) {
    .nav-email {
      font-size: 0.9rem;
    }

    .font-input {
      width: 78% !important;
    }
  }

  @media screen and (max-width: 480px) {
    .adminPanel-nav-link,
    .active-adminPanel-nav-link {
      padding-left: 2px !important;
      padding-right: 8px !important;
    }
  }  

  @media screen and (max-width: 564px) {
    .logo-container {
      display: none;    
    }
    #file-input-label {
      padding: 2vw !important;
      cursor: pointer;
    }
    .main-appearance-container {
      margin-bottom: 1rem;
    }
  }

  @media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .header-nav {
      display: flex;
      width:auto;
    }

  }
  @media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
   .header-nav {
      display: flex; 
      width:auto;
    }
  }

  @media (max-width: 375px) {
    .adminPanel-nav-link, .active-adminPanel-nav-link {
      padding-left: 0px !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .fonts-adjust{
      font-size: 11px;
    }
  }

  @media (max-width: 350px) {
    .adminPanel-line {
      font-size: 12px;
      padding-left: 5px !important;
    }
    .appearance-icon,
    .user-icon,
    .subscriptions-icon {
      width: 1rem;
      height: 1rem !important;
    }
    .fonts-adjust{
      font-size: 8px;
    }
  }

  @media (max-width: 280px) {
    .cancel-btn,
    .delete-btn {
      width: 50px !important;
    }
    .upload-btn-main {
      gap: 4rem !important;
    }
    .logo-name {
      margin-left: 0px !important;
    }
    .language-section {
      justify-content: initial !important;
      gap: 2rem;
    }
    .font-section {
      justify-content: initial !important;
      gap: 3.5rem !important;
    }
    .fonts-adjust{
      font-size: 6px;
    }
  }

  .color-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 1rem;
  }

  .logo-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .select-file-input {
    padding: 1.25rem 1.25rem;
    width: 8rem;
  }

  .select-file-input-ar {
    padding: 0.5rem 2.35rem !important;
    width: 8rem;
  }

  .language-select .form-select {
    width: 7.75rem;
  }

  .font-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-bottom: 1rem;
  }

  .font-selection-dropdown .form-select {
    width: 7.75rem;
  }

  .logo-container {
    position: relative;
    margin-left: -8rem;
  }

  .remove-icon-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .remove-logo-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    background-color: red;
    padding: 0.30rem;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

  .submit-button-container {
    margin-left: -10rem;
  }

  .admin-panel-tabs-container {
    overflow-y: hidden;
  }

.admin-panel-tabs-container::-webkit-scrollbar {
  display: none;
}

.color-dropdown-selected-option {
  display: flex;
  align-items: center;
  padding-left: 0.3rem;
  gap: 0.3rem;
}

.color-dropdown-option {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.color-dropdown-option .color {
  display: block;
  width: 0.7rem;
  height: 0.7rem;
}

#addUserModal {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 0;
}

.admin-panel-tabs-container .nav-link{
    color: #0c0c0c;
    display: flex;
    align-items: center;
}

.admin-panel-tabs-container .nav-item button {
    padding: 0.45rem;
}