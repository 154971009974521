.pricing-divider {
  border-radius: 20px;
  position: relative;
}

.pricing-divider h4 {
  font-size: 2.1rem;
}

.pricing-divider-img {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 80px;
}

.deco-layer {
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

.btn-custom {
  background: #c64545;
  color: #fff;
  border-radius: 20px;
}

.img-float {
  width: 50px;
  position: absolute;
  top: -3.5rem;
  right: 1rem;
}

.princing-item {
  background: #f5f5f5;
  color: #134158 !important;
  transition: all 150ms ease-out;
  border-radius: 25px;
  border: 1px solid #ddd;
  filter: blur(0.5px);
  transition: transform ease 300ms;
}

.princing-item:hover {
  transform: translate(0, -10px);
  position: relative;
  color: #fff !important;
  z-index: 10;
  /* background-color: #1f5d7a !important; */
  color: #fff;
  filter: none !important;
}

.plan-title {
  font-weight: 600;
  padding-top: 20px;
}

.princing-item:hover {
  /* background-color: #1f5d7a; */
  z-index: 100 !important;
  color: #ffffff;
}

.princing-item-container:hover {
  z-index: 100 !important;
}

.highlighted-choose-plan-btn,
.highlighted-choose-plan-btn:hover {
  padding: 7px 10px !important;
  border: none;
  font-size: 15px;
  border-radius: 5px;
}

.highlighted-choose-plan-btn,
.highlighted-choose-plan-btn:hover {
  background-color: #fff;
  color: #092636 !important;
}

.choose-plan-btn {
  background-color: #1d607e;
  color: #fff !important;
  border-radius: 30px;
}

.choose-plan-btn:hover{
  background-color: #1d607e;
  color: #fff !important;
}

.register-tenant-btn {
  background-color: #1d607e !important;
  color: #fff !important;
  border-radius: 30px;
}

.register-tenant-btn:hover{
  background-color: #1d607e !important;
  color: #fff !important;
}

.princing-item:hover .choose-plan-btn {
  background-color: #fff;
  color: #1d607e !important;
}

.main-heading-titles {
  color: #1d607e;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .req-for-demo-img {
    max-width: 65vh;
    margin-top: -10px !important;
  }

  .subscription-plan {
    margin-bottom: 2rem !important;
  }

  .contact-us-sec {
    margin-top: -5rem !important;
  }
}

@media (max-width: 912px) {
  .req-for-demo-img {
    max-width: 130%;
    margin-top: 0.3rem !important;
  }

  .contact-us-sec {
    margin-top: -6rem !important;
  }
}

@media (max-width: 540px) {
  .sales-fission-img {
    margin-left: 0px !important;
  }

  .contact-us-sec {
    width: 90% !important;
    margin-top: -5.5rem !important;
  }

  .req-for-demo-img {
    max-width: 83%;
    margin-right: 1.5rem;
    margin-top: 0rem !important;
  }
}

@media (max-width: 280px) {
  .subscription-plan {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .req-for-demo-img {
    margin-top: 0.5rem !important;
  }
}

@media (min-width: 1025px) and (max-width: 1280px) {

  .req-for-demo-img {
    max-width: 60vh;
    margin-top: -15px !important;
  }
}

@media (min-width: 1281px) {
  .sales-fission-img {
    margin-top: -10px !important;
  }
}

@media screen and (min-height: 1366px) {
  .req-for-demo-img {
    max-width: 120%;
  }

  .contact-us-sec {
    margin-top: -6rem !important;
  }
}