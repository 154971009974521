/* Add these styles to your CSS file */
.doc-table .table {
  width: 80%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  margin: 20px 0;
}

.doc-table
.table th,
.table td {
  color: rgb(66, 54, 54);
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}

.doc-table
.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.doc-table
.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.doc-table
.table tr:hover {
  background-color: #ddd;
}

.fade.show {
  opacity: 1;
}
.doc-modal .modal {
  background-color: #0006;
}