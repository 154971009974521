.home-mid-body {
  background-image: url('./../../assets/body-bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.quick_scroll{
  display: none;
  position: fixed;
  bottom: 38vh;
  right: 2px;
  font-size: 3rem;
  color: #3B7995;
  opacity: 0.8;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.home-body ul li {
  margin-bottom: 1.4rem;
}

.main-nav {
  box-shadow: 0px 0px 9px 0px lightgray;
  position: fixed;
  top: 0px;
  z-index: 101;
  background-color: white;
}

.sign-gif img {
  width: 32rem;
}

.document-section {
  background: #f4f9fa;
  ;
}

.heading-doc {
  margin-top: 12vh;
}

.want-to-know {
  background: #1f5d7a;
}

.about-section {
  display: flex;
  align-items: baseline;
  gap: 9px;
  color: white;
}

.heading-one {
  margin-left: 14%;
  padding-top: 2vh;
  color: white;
}

.demo-request-btn {
  padding: 5px;
  border-radius: 11px;
  color: white;
  border: none;
  background: orangered;
}

.reg-btn,
.reg-btn:hover {
  background-color: rgb(232, 232, 232);
  color: #1f5d7a !important;
}

.cf:before,
.cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.main-container {
  background: #fff;
  max-width: 1000px;
  margin: 25px auto 25px auto;
  position: relative;
}

.container {
  position: relative;
}

/*animation element*/

.animation-element {
  opacity: 1;
  position: relative;
}

/*animation element sliding left*/

.animation-element.slide-left {
  opacity: 1;
  -moz-transition: all 500ms linear;
  -webkit-transition: all 500ms linear;
  -o-transition: all 500ms linear;
  transition: all 1300ms linear;
  -moz-transform: translate3d(-70, 0px, 0px);
  -webkit-transform: translate3d(-70, 0px, 0px);
  -o-transform: translate(-70, 0px);
  -ms-transform: translate(-70, 0px);
  transform: translate3d(-70, 0px, 0px);
}

.animation-element.slide-left.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

/*animation slide right styled for testimonials*/

.animation-element.slide-right {
  opacity: 1;
  -moz-transition: all 500ms linear;
  -webkit-transition: all 500ms linear;
  -o-transition: all 500ms linear;
  transition: all 1300ms linear;
  -moz-transform: translate3d(70, 0px, 0px);
  -webkit-transform: translate3d(70, 0px, 0px);
  -o-transform: translate(70, 0px);
  -ms-transform: translate(70, 0px);
  transform: translate3d(70, 0px, 0px);
}

.animation-element.slide-right.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.doc-vd img {
  width: 80rem;
}

.animation-element.slide.testimonial {
  float: left;
}

.requestForDemoBtn {
  padding: 5px;
  border-radius: 11px;
  color: white;
  border: none;
}

#navbarSupportedContent {
  justify-content: left;
}
.EasyToShareTextEn{
width: 70%;
}

.top-right {
  position: absolute;
  top: 5rem;
  left: 5%;
  width: 60%;
  font-size: 1.5rem;
  color: white;
}
.imageTwo{
width: 100%;
height:100%;
display: flex;
justify-content: center;
align-items: center;
}


.img-fluid {
  max-width: 92%;
  height: auto;
}

ul.navbar-nav li.nav-item {
  margin-right: 30px;
}

.navbar-nav .nav-item .nav-link {
  color: #c2d6de;
}

.navbar-nav .nav-item :hover {
  color: white
}

.navbar-nav .nav-item .nav-link:focus {
  border-bottom: 2px solid white;
  color: white;
}

.img-container {
  position: relative;
}

.image {
  position: absolute;
}

.mw-75 {
  max-width: 75%;
}

.height-about {
  height: 90vh;
}

.respnsive-flexDirection {
  justify-content: end;
  margin-top: 3rem;
  padding-top: 3rem;
}

.aboutUs-background-img {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.aboutus-logo {
  max-width: 35%;
}

/* Media Queries */

@media (max-width: 1400px) {
  .device-img-1 {
    display: flex;
    justify-content: center;
  }

  .easy-to-share-height {
    height: 110vh !important;
  }

  .device-img-2 {
    display: none;
  }
 
  .top-right {
    position: absolute;
    top: 3rem;
    left: 5%;
    width: 80%;
  }

}

/* Mobile Devices */

@media (max-width: 1280px) {
  .top-right {
    position: absolute;
    left: 5%;
    width: 80%;
    font-size: 1.4rem;
  }
  .easy-to-certify-img {
    margin-top: 1.5rem !important;
    height: 55vh !important;
    margin-left: 2rem;
  }

  .easy-to-share-height {
    height: 100vh !important;
  }

  .height-about {
    height: 100vh;
  }

  ul.navbar-nav li.nav-item {
    margin-right: 0px;
  }
}

@media (max-width: 1024px) {
  .easy-to-certify-container {
    margin-top: 10rem;
  }

  .easy-to-share-height {
    height: 46vh !important;
  }

  .easy-to-certify-section {
    gap: 0rem !important;
  }

  .easy-to-certify-img {
    width: 50vw !important;
    height: 35vh !important;
    margin-left: 0rem !important;
  }
}

@media (max-width: 912px) {
  .easy-to-share-height {
    height: 53vh !important;
  }

  .easy-to-certify-container {
    margin-top: 0rem;
  }

  .easy-to-certify-img {
    width: 50vw !important;
    height: 34vh !important;
    margin-left: 0rem !important;
  }

  .easy-to-share-text {
    margin-top: 1rem !important;
    margin-left: 0.6rem !important;
  }
}

@media (max-width: 820px) {
  .easy-to-share-height {
    height: 62vh !important;
  }
}

@media (max-width: 768px) {
  .easy-to-share-text {
    padding: 0.5rem;
  }
  

  .aboutUsText{
    width: 18rem;
    font-size: 11px;
  }

  .w-50-on-mobile {
    width: 57vh;
    padding: 1rem;
  }

  .responsive {
    display: none;
  }

  .easy-to-share-height {
    height: 70vh !important;
  }

  .respnsive-flexDirection {
    justify-content: center;
    flex-direction: column;
    margin-top: 0rem;
    padding-top: 0rem;
  }

  .custom-col-md-4 {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0.5rem;
  }

  .custom-col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }

  .aboutUs-background-img {
    background-size: 100% 142%;
    background-repeat: no-repeat;
  }

  .height-about {
    margin-top: calc(-4em * 2);
  }

  .aboutus-logo {
    max-width: 30vh
  }
  .top-right {
    position: absolute;
    top: 1rem;
    left: 5%;
    width: 80%;
    font-size: 0.8rem;
  }
}

@media (width: 768px) {
  .top-right {
    font-size: 1.3rem;
  }
}

@media (max-width: 540px) {
  .sign-gif img {
    width: 22rem;
  }

  .responsive {
    display: none;
  }

  .easy-to-certify-section {
    gap: 0rem !important;
  }

  .easy-to-certify-img {
    width: 55vw !important;
    height: 30vh !important;
    margin-left: 0rem !important;
  }

  .easy-to-share-height {
    height: 76vh !important;
  }

  .aboutUs-background-img {
    background-size: 100% 145%;
    background-repeat: no-repeat;
  }

  .height-about {
    margin-top: calc(-3em * 2);
  }

  .aboutus-logo {
    max-width: 28vh;
  }
}
.aboutUsText {
 color: white;
}

@media (max-width:465px) {
  .top-right {
    position: absolute;
    left: 5%;
    width: 80%;
    font-size:12px;
  }
}

@media (max-width: 431px) {
  .easy-to-share-height {
    height: 48vh !important;
  }

  .easy-to-share-text {
    margin-top: 0.5rem !important;
  }

  .w-50-on-mobile {
    width: 34vh;
    padding: 0rem;
  }

  .background-pattern-img {
    margin-bottom: calc(-1em*2)
  }

  .height-about {
    height: 78vh;
    margin-top: calc(-2em * 2);
  }

  .aboutus-logo {
    max-width: 22vh;
  }

  .respnsive-flexDirection {
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 3rem;
    padding-top: 0rem;
  }

  .aboutUs-background-img {
    background-size: 100% 121%;
    background-repeat: no-repeat;
  }
  .top-right {
    position: absolute;
    left: 5%;
    width: 80%;
    font-size: 10px;
    top: 0.5rem;
  }
}

@media (max-width: 412px) {
  .sign-gif img {
    width: 18rem;
  }

  .responsive {
    display: none;
  }

  .easy-to-share-height {
    height: 45vh !important;
  }

  .height-about {
    margin-top: calc(-2em * 2);
  }
}

@media (max-width: 376px) {
  .easy-to-share-height {
    height: 58vh !important;
  }

  .w-50-on-mobile {
    width: 42vh;
    padding: 0rem;
  }

  .aboutus-logo {
    max-width: 24vh;
  }

  .height-about {
    height: 95vh;
    margin-top: calc(-2em * 2)  ;
  }
  .top-right {
    position: absolute;
    left: 5%;
    width: 80%;
    font-size: 8px;
  }
}

@media (max-width: 360px) {
  .easy-to-share-height {
    height: 50vh !important;
  }

  .w-50-on-mobile {
    width: 37vh;
    padding: 0rem;
  }

  .background-pattern-img {
    margin-bottom: 22rem
  }
}
@media (max-width:320px) {
  .top-right {
    position: absolute;
    left: 3%;
    width: 92%;
    font-size: 8x;
    top: 3px;
  }
  
}

@media (max-width: 280px) {
  .sign-gif img {
    width: 16rem;
  }
  .aboutUsText {
    width: 13rem;
    font-size: 11px;
}

  .responsive {
    display: none;
  }

  .easy-to-share-height {
    height: 43vh !important;
  }

  .easy-to-share-text {
    margin-top: 0.2rem !important;
    margin-left: 0.3rem !important;
  }

  .w-50-on-mobile {
    width: 32vh;
    padding: 0rem;
  }
}

/* Tablet Devices */

@media (min-width: 769px) and (max-width: 992px) {
  .easy-to-share-text {
    padding: 1rem;
  }

  .aboutUsText{
    width: 18rem;
    font-size: 11px;
  }

  .w-50-on-mobile {
    width: 46vh;
  }

  .mt-5rem {
    margin-top: 5rem;
  }

  .responsive {
    display: none;
  }

  .respnsive-flexDirection {
    justify-content: center;
    flex-direction: column;
    margin-top: 0rem;
    padding-top: 0rem;
  }

  .custom-col-md-4 {
    flex: 0 0 auto;
    width: 100%;
  }

  .custom-col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }

  .aboutUs-background-img {
    background-size: 100% 140%;
    background-repeat: no-repeat;
  }

  .height-about {
    margin-top: calc(-5em * 2);
  }

  .aboutus-logo {
    max-width: 30vh
  }

  .background-pattern-img {
    margin-bottom: 22rem
  }

  .contact-us-images-div {
    padding-top: 10rem;
  }
}

/* Medium Screen devices */

@media (min-width: 992px) {
  .mt-5rem {
    margin-top: 5rem;
  }

  .background-pattern-img {
    margin-bottom: 22rem
  }
}

/* Computer */

@media (min-width: 1390px) {
  .device-img-1 {
    display: none;
  }

  .device-img-2 {
    display: flex;
  }

  .easy-to-share-height {
    height: 70vh !important;
  }

  .background-pattern-img {
    margin-bottom: 22rem
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .easy-to-share-text {
    margin-left: 2rem;
  }

  .background-pattern-img {
    margin-bottom: 22rem
  }

  .contact-us-images-div {
    padding-top: 15rem;
  }
}

.responsive-screen-com-img img {
  display: block;
  padding: 2rem;
}

@media (min-width: 769px) {
  .responsive-screen-com-img img {
    display: none;
  }
}
@media (width: 820px) {
  .top-right {
    top: 0.5rem;
    font-size:1.3rem;
  }
  .aboutUsText {
    width: 26rem;
    font-size: 19px;
}
}
@media (width: 912px) {
  .top-right {
    top: 0.5rem;
    font-size:1.3rem;
  }
  .aboutUsText {
    width: 40rem;
    font-size: 28px;
}
}

@media screen and (max-width: 1024px) and (max-height: 600px) { 
  .easy-to-share-height {
    height: 100vh !important;
  }
 }
@media screen and (min-height: 1366px) { 
  /* .easy-to-share-height {
    height: 100vh !important;
  } */
  .respnsive-flexDirection {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 0rem;
    padding-top: 0rem;
  }

  .custom-col-md-4 {
    flex: 0 0 auto;
    width: 75%;
  }

  .custom-col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }

  .aboutUs-background-img {
    background-size: 100% 140%;
    background-repeat: no-repeat;
  }
  .height-about {
    height: 100vh;
    margin-top: calc(-5em*2);
}

  /* .height-about {
    margin-top: calc(-6em * 2);
  } */

  .aboutus-logo {
    max-width: 30vh
  }
 }
 @media (max-width: 1471px) {
  .quick_scroll{
    display: none;
  }}

@media screen and (min-width: 200px) and (max-width: 1100px) {
  .contact-us-images-div {
    padding-top: 16rem
  }
}
