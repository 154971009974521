.footer-common {
    /* background: #1F5D7A; */
    color: #fff;
    height: 80px;
    /* position: absolute; */
    bottom: 0;
    /* width: 100vw; */
}

.footer_para {
    font-size: 16px;
    display: contents;
}

.footer-common a {
    text-decoration: none;
    color: #fff;
    /* height: 35px; */
}

/* Media Queries */

@media (max-width: 992px) {
    .footer-common {
        margin-top: 0rem !important;
    }
}

@media screen and (min-height: 1366px) { 
    .footer-common {
        margin-top: 0rem !important;
    }
}

@media (max-width: 280px) {
    .footer_para span {
        font-size: 14px !important;
    }
}