.selection-feilds {
    border: 1px solid grey;
    border-radius: 10px;
    margin: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 3fr;
    justify-content: center;
    padding-left: 0.5rem;
    align-items: center;
    width: 8rem;
    cursor: pointer;
}