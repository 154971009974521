.my-documents-navbar {
    border-bottom: 1px solid #707070;
    overflow-x: scroll;
    overflow-y: hidden;
    transition: transform 500ms;
}

.my-documents-navbar::-webkit-scrollbar {
    display: none;
}

.my-documents-nav-link {
    color: #5C5C5C;
    padding-top: 10px;
    padding-bottom: 5px;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    border-bottom: 4px solid transparent;
}

.active-my-documents-nav-link,
.my-documents-nav-link:hover {
    border-bottom: 4px solid #092636;
    color: #092636;
    font-weight: bold;
}

.my-documents-sortbar {
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #556FF659;
}

.my-documents-sort-select {
    padding: 2px;
    border-radius: 5px;
    font-size: 14px;
}

.my-documents-list-container {
    border: 1px solid #206888;
    border-radius: 21px;
}

.my-documents-titles-header-row {
    border-bottom: 1px solid #707070;
    font-weight: bold;
}

.my-documents-list-header-row {
    border-bottom: 1px solid #ccc;
}

.my-documents-list-header-row:last-child {
    border: none;
}

.document-title-col {
    border-left: 4px solid #F05B5B;
}

.documents-dropzone {
    border-radius: 10px;
    padding: 6px 16px;
    border: 3px dashed orange;
    cursor: pointer;
    background-color: #6798ae;
    font-size: 12px;
}

.custom-truncate {
    display: inline-block;
    max-width: 300px;
    /* Adjust the maximum width as per your requirement */
    white-space: nowrap;
    text-overflow: ellipsis;
}

.my-documents-sortbar-padd {
    padding: 21px;
}

.deleteBtn {
    padding: 2px 2px 2px 2px;
    /* background-color: rgb(144, 55, 73); */
    width: 4rem;
    font-size: 16px !important
}

.deleteBtn:hover {
    background-color: rgb(144, 55, 73) !important;
}

.send-btn {
    color: white;
    font-size: 12px;
    padding: 5px;
    width: 6rem;
    border: none;
    border-radius: 6px;
}

.actions-btn-alinment{
    padding-left: 4rem;
}

.send-btn-disabled {
    color: white;
    font-size: 12px;
    width: 6rem;
    padding: 5px;
    border: none;
    border-radius: 6px;
    opacity: 0.7;
}

.status-draft {
    cursor: default !important;
}


.my-documents-sortbar-padd {
    padding: 21px;
}

.custom-tooltip {
    --bs-tooltip-bg: var(--bs-primary);
}

.tooltipdrop-content {
    display: none;
    position: absolute;
    margin-left: 10px;
    margin-bottom: 7px;
    font-size: 14px;
    color: #f3f3f3eb;
    padding: 4px 30px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 8px;
}

.tooltipdrop-content-top {
    display: none;
    position: absolute;
    margin-left: 25px;
    margin-bottom: 7px;
    margin-top: -100px;
    font-size: 14px;
    color: #f3f3f3eb;
    padding: 4px 30px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 8px;
}

.tooltipdrop-content a {
    color: black;
    padding: 4px 20px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    border-radius: 5px;
}

.tooltipdrop-content a:hover {
    background-color: #f1f1f1;
    transition-delay: 5s;
}

.tooltip-drop:hover .tooltipdrop-content {
    display: block;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: rgb(142, 142, 142);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #151515;
}

.recipient-status-bg {
    background-color: white;
    width: 100%;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-size: 12px;
}

.recipient-status-complete {
    background-color: green;
    border-radius: 50%;
    color: white;
    font-size: 8px;
    text-align: center;
    justify-content: center;
    padding-bottom: 0px !important;
    margin: 0px;
}

.recipient-status-reject {
    background-color: rgba(255, 2, 2, 0.831);
    border-radius: 50%;
    color: white;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    padding-bottom: 0px !important;
    padding-top: 2px !important;
    margin: 1px;
}

.recipient-status-pending {
    background-color: rgba(255, 238, 2, 0.831);
    border-radius: 50%;
    color: white;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    margin: 0px;
}

.signers-view {
    text-align: left;
    font: normal normal medium 20px/24px Urbanist;
    letter-spacing: 0px;
    /* color: #206888; */
    opacity: 1;
}

.alert-message {
    width: 20%;
    position: fixed;
    bottom: 0;
    right: 20px;
    cursor: pointer;
}

.color-black {
    color: black;
}

.red-text {
    color: rgba(255, 2, 2, 0.831);
}

table.borderless td {
    border-right: none !important;
    border-left: none !important;
    min-height:75px !important;
}

table {
    border: 1px solid black;
}

/* .w-72 {
    width: 72% !important;
} */

.recall{
    height: 1rem;
}

.recall:hover{
    cursor: pointer;
}

@media screen and (max-device-width: 300px) {
    .f-small-device {
        font-size: x-small;
    }
}

@media (max-width: 1280px) {
    .my-docs-table {
        width: 88% !important;
    }
}

@media (max-width: 1024px) {
    .my-docs-table {
        width: 92% !important;
    }
}

@media screen and (max-width: 550px) {
    .my-documents-navbar {
        transform: scale(0.9);
    }
}

@media screen and (max-width: 450px) {
    .my-documents-navbar {
        transform: scale(0.8);
    }
}

.my-documents-navbar::-webkit-scrollbar {
    display: none;
}

.tooltip-top-position {
    margin-top: -150px !important;
}