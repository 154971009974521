.company-registration-container {
    background-image: url('./../../assets/body-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
}

.company-reg-nav {
    background-color: #1f5d7a;
}
.footerHieght{
    max-height: 35px;
}
/* .choose-plan-btn:hover{
    color: black !important;
    background: white;
    border: 1px solid darkgray;
} */

.input-box{
   width: 3rem !important;
   height: 2.2rem;
   gap: 1rem;
   border-radius: 5px;
   margin: 0 5px; 
}

.validate-btn {
    background-color: #092636;
    padding: 7px;
    color: rgba(255, 255, 255, 0.81);
    border: none;
    font-size: 15px;
    border-radius: 15px;
}

.code-sent-text {
    color: #b6b6b6;
}

.validate-button {
    display: flex;
    justify-content: center;
    margin-right: 11rem;
    margin-top: 4vh;
}

.clear-otp-btn,
.validate-otp-btn,
.resend-otp-btn {
    background-color: #1d607e !important;
    color: #fff !important;
  }

.company-registration-container .btn.disabled,
.company-registration-container .btn:disabled {
    background-color: #092636;
    color: #fff !important;
}

/* Media Queries */
@media (max-width: 912px) {
    .company-registration-block {
        display: block !important;
    }
    .company-registration-form {
        width: 100% !important;
    }
    .otp-buttons {
        justify-content: center !important;
        gap: 1rem;
        margin-top: 1.5rem !important;
    }
}

@media (max-width: 540px) {
    .clear-otp-btn,
    .validate-otp-btn,
    .resend-otp-btn {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .main-container {
        padding-bottom: 1rem;
    }
    .otp-box-alignment {
        margin: 0px !important;
        width: 100% !important;
    }
}

@media (max-width: 414px) {
    .company-registration-input-field {
        margin-right: 0rem!important;
        margin-left: 0rem!important;
    }
    .dasthakat-logo {
        margin-left: 20px !important;
    }
    .language-switcher-btn {
        margin-right: 20px !important;
    }
}

@media (max-width: 375px) {
    .clear-otp-btn,
    .validate-otp-btn,
    .resend-otp-btn {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
}

@media (max-width: 280px) {
    .otp-buttons {
        gap: 0rem;
    }
    .dasthakat-logo {
        width: 10px !important;
        margin-left: 10px !important;
    }
    .language-switcher-btn {
        margin-right: 5px !important;
    }
    .code-sent-text {
        font-size: 1rem !important;
    }
}