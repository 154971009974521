.homeBtn{
    padding: 1rem;
    border: none;
    background: #206888;
    color: white;
    border-radius: 12px;
    margin-top: 5vh;
    margin-left: 12rem;
    text-decoration: none;
}
.heading{
    font-size: 3rem;
    color: rgba(196, 0, 0, 0.943);
    text-align: center;
    margin-top: 20vh;
}