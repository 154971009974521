* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    --site-primary-color: #1E607E;
    --site-secondary-color: #484848;
}

body {
    /* font-family: Lato, Roboto, Helvetica, sans-serif; */
    font-family: Urbanist, Medium, Lato, Roboto, Helvetica, sans-serif;
}

/* .on-off-toggle {
    width: 100px;
    height: 24px;
    position: relative;
    display: inline-block;
}

.on-off-toggle__slider {
    width: 70px;
    height: 24px;
    display: block;
    border-radius: 34px;
    background-color: #d8d8d8;
    transition: background-color 0.4s;

    &:before {
        content: '';
        display: block;
        background-color: #003654;
        box-shadow: 0 0 0 1px #949494;
        bottom: 3px;
        height: 18px;
        left: 3px;
        position: absolute;
        transition: .4s;
        width: 18px;
        z-index: 5;
        border-radius: 100%;
    }

    &:after {
        display: block;
        line-height: 24px;
        text-transform: none !important;
        font-size: 12px;
        font-weight: bold;
        content: 'العربية' !important;
        color: #484848;
        padding-left: 26px;
        transition: all 0.4s;
        font-size: 13px !important;
    }
}

.on-off-toggle__input {
    position: absolute;
    opacity: 0;
}

.on-off-toggle__input:checked+.on-off-toggle__slider {
    background-color: #d8d8d8;

    &:before {
        transform: translateX(45px);
    }

    &:after {
        content: 'English' !important;
        color: #0d0d0d;
        padding-left: 8px;
        font-size: 9px !important;
    }
} */

.PhoneInputInput {
    border: none;
    background: transparent;
    outline: none;
    background: white;
 }
.otp-box-alignment{
    display: flex;
    flex-direction: column !important;
}

