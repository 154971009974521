.signup-form {
    background-color: rgb(255, 255, 255) !important;
    width: 27%;
    padding: 36px 46px !important;
    border-radius: 10px;
}

.signup-vector img {
    height: 20vw;
    width: 100%;
    margin-top: 4vw;
}

.signup-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-vector-section {
    display: flex;
    justify-content: space-evenly;
}

.dasthkath-logo-signup {
    position: absolute;
    bottom: 59px;
    left: 16vw;
    height: 6vw;
}

.signup-form-input-field {
    padding: 8px !important;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
    outline: none;
}

.signup-form-input-field input {
    width: 100%;
    border: none;
    outline: none;
}

.btnsignup {
    padding: 7px !important;
    color: rgba(255, 255, 255, 0.81);
    border: none;
    font-size: 15px;
    border-radius: 15px;
    width: 100% !important;
}

.signup-body {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 95vh !important;
}

.validation-msg {
    font-size: 14px;
}

@media screen and (max-width: 1280px) {
    /* Nest hub max */
    .validation-msg {
        font-size: 13px !important;
    }
    .signup-form {
        width: 39%;
    }
    .signup-vector img {
        height: 24vw;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {

    /* Nest hub  */
    .dasthkath-logo-signup {
        bottom: 32px;
        left: 16vw;
        height: 10.5vw;
    }
    .validation-msg {
        font-size: small;
    }
    .signup-form {
        width: 43%;
        padding: 5px 43px !important;
        margin-top: calc(-14*1vh) !important
    }

    .signup-vector img {
        height: 22vw;
    }
}

@media screen and (width: 768px) {
    .signup-vector img {
        height: 31vw !important;
        margin-top: 7vw;
    }
    .signup-vector-section {
        margin-top: calc(-4*-1vh) !important;
    }
    .dasthkath-logo-signup {
        bottom: 36px !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {

    /* surface pro 7 , ipad mini , ipad air */
    .signup-vector img {
        display: none;
    }

    .dasthkath-logo-signup {
        bottom: 56px;
        left: 11vw;
        height: 23vw;
        width: 9rem;
    }

    .signup-vector-section {
        margin-right: 4rem;
        margin-top: calc(-4*-3vh);
    }

    .signup-body {
        background-size: 100% 101%;
        height: 97vh !important;
    }

    .signup-form {
        width: 34rem;
        padding: 51px 33px !important;
    }

    .btnsignup {
        width: 100% !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px) {

    /* surface duo */
    .signup-form {
        width: 68%;
        padding: 4px 31px !important;
        border-radius: 10px;
    }

    .validation-msg {
        font-size: small;
    }

    .dasthkath-logo-signup {
        bottom: 29px;
        left: 10vw;
        height: 24vw;
    }

    .signup-body {
        height: 97vh !important;
    }

    .signup-vector-section {
        margin-top: calc(-1*-2vh);
        margin-right: 3rem;
    }

    .signup-vector img {
        display: none;
    }

    .responsive-fields-names {
        margin-bottom: 0rem !important;
    }

    .cnfpaswrd {
        margin-bottom: 0rem !important;
    }

    .responsive-form {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
    }

    .signup-form-input-field {
        padding: 4px !important;
        border-radius: 10px;
        border: 1px solid grey;
        width: 100%;
        outline: none;
    }

    .btnsignup {
        font-size: 15px;
        border-radius: 15px;
        width: 100% !important;
    }
}

@media screen and (min-width:320px) and (max-width:414px) {

    /* samsung galaxy A51/71 , samsung galaxy s20 ultra , 
    samsung galaxy s8+ , pixel 5 , iphone 12 pro , Iphone xr*/
    .signup-vector img {
        display: none;
    }

    .signup-form {
        width: 21.5rem;
        padding: 10px 19px !important;
    }

    .validation-msg {
        font-size: small !important;
    }

    .signup-vector-section {
        margin-top: 9vh;
        margin-right: 1rem;
    }

    .responsive-form {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
    }

    .responsive-fields-names {
        margin-bottom: 0rem !important;
    }

    .cnfpaswrd {
        margin-bottom: 0rem !important;
    }

    .signup-button {
        margin-top: 0rem !important;
    }

    .btnsignup {
        width: 100% !important;
    }

    .signup-btn {
        margin-top: 0.5rem !important;
    }

    .signup-body {
        height: 96.3vh !important;
        background-size: 100% 101%;
    }

    .dasthkath-logo-signup {
        bottom: 53px;
        left: 6vw;
        height: 35vw;
    }
}

@media screen and (min-width:275px) and (max-width:300px) {

    /* galaxy fold */
    .signup-vector img {
        display: none;
    }

    .validation-msg {
        font-size: 11px !important;
    }

    .signup-form {
        width: 15rem;
        padding: 8px 16px !important;
    }

    .responsive-form {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
    }

    .signup-vector-section {
        margin-right: 0.5rem !important;
        margin-top: 2.3rem !important;
    }

    .responsive-fields-names {
        margin-bottom: 0rem !important;
    }

    .cnfpaswrd {
        margin-bottom: 0rem !important;
    }

    .signup-button {
        margin-top: 0rem !important;
    }

    .btnsignup {
        width: 100% !important;
    }

    .signup-form-input-field {
        padding: 8px !important;
        height: 2rem;
    }

    .dasthkath-logo-signup {
        bottom: 38px;
        left: 3vw;
        height: 13vh;
        width: 5rem;
    }

    .signup-body {
        height: 98.6vh !important;
        margin-top: calc(-2*2vh);
        background-size: 100% 107%;
    }

    .signup-btn {
        margin-top: 0.5rem !important;
    }
}

@media screen and (width: 375px) {
    /* iphone SE  */
    .signup-vector img {
        display: none;
    }

    .signup-form {
        width: 18rem;
        padding: 8px 16px !important;
    }

    .responsive-form {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
    }

    .signup-vector-section {
        margin-top: calc(-1*-2.25vh);
        margin-right: 1.5rem;
    }

    .validation-msg {
        font-size: 11.5px !important;
    }

    .responsive-fields-names {
        margin-bottom: 0rem !important;
    }

    .cnfpaswrd {
        margin-bottom: 0rem !important;
    }

    .signup-button {
        margin-top: 0rem !important;
    }

    .btnsignup {
        width: 100% !important;
    }

    .signup-form-input-field {
        padding: 8px !important;
        height: 2rem;
    }

    .dasthkath-logo-signup {
        bottom: 51px;
        left: 6vw;
        width: 5rem;
        height: 24vw;
    }

    .signup-body {
        height: 98.5vh !important;
        margin-top: calc(-1 * 4vh);
        background-size: 100% 102%;
    }

    .signup-btn {
        margin-top: 0.5rem !important;
    }
}

@media screen and (width: 360px) {

    /* samsung galaxy s8+ */
    .signup-vector img {
        height: 41.5vw;
    }

    .signup-form {
        width: 19rem;
        padding: 13px 35px !important;
    }

    .responsive-form {
        margin-bottom: 0rem !important;
        margin-top: 0rem !important;
    }

    .validation-msg {
        font-size: 10px;
    }

    .signup-vector-section {
        margin-top: 5vh;
        margin-right: 1rem;
    }

    .responsive-fields-names {
        margin-bottom: 0rem !important;
    }

    .cnfpaswrd {
        margin-bottom: 0rem !important;
    }

    .signup-button {
        margin-top: 0rem !important;
    }

    .btnsignup {
        width: 100% !important;
    }

    .signup-form-input-field {
        padding: 8px !important;
        height: 2rem;
    }

    .dasthkath-logo-signup {
        bottom: 52px;
        left: 6vw;
        height: 30vw;
    }

    .signup-body {
        height: 99.5vh !important;
        margin-top: calc(-1 * 4.5vh);
    }

    .signup-btn {
        margin-top: 0.5rem !important;
    }
}