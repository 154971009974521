.settings-form-container {
    background-color: #F8F8F8;
    border-radius: 28px;
}

.input-label {
    font-weight: bold;
}

.name-input {
    width: 100%;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    outline: none;
    padding: 8px 15px;
}

.create-btn{
    background-color: rgb(144, 55, 73);
    font-size: 13px;
    font-weight: 600;
    height: 4.6vh;
    border-radius: 4px;
    border: none;
}

.save-btn,
.save-btn:hover {
    color: #fff;
}

.my-signature {
    border-radius: 8px;
    box-shadow: 0px 8px 6px #00000029;
    background-color: #fff;
}

.uploadImageLabel {
    color: #fff;
    border-radius: 8px;
}

.no-signature {
    color: rgb(231, 79, 79);
}

.close-icon {
    position: absolute;
    right: 18px;
    top: 10px;
    border: none;
    background-color: #fff;
    color: #877c7c;
    font-size: 20px;
}

.signCanvas {
    border: 2px dashed rgba(0, 0, 0, 0.645);
    border-radius: 5px;
    /* max-width: 100%; */
}

/* Media Queries */
@media (max-width: 1024px) {
    .settings-form-container {
        margin-bottom: 3rem;
    }
    .create-btn {
        height: 5.9vh;
    }
    .uploadImageLabel {
        padding-top: 1.2rem !important;
        padding-bottom: 1.1rem !important;
    }
}

@media (max-width: 912px) {
    .profile-form,
    .password-form {
        padding: 0rem !important;
    }
    .create-btn,
    .uploadImageLabel {
        height: 2.5rem !important;
    }
    .signature-tab {
        margin-left: 0px !important;
    }
}

@media (max-width: 600px) {
    .signature-modal {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
    .signature-modal-header,
    .signature-modal-footer {
        padding: 10px;
    }
    .signCanvas {
        width: 360px;
    }
}

@media (max-width: 540px) {
    .account-settings {
        margin: 0rem 1.5rem;
    }
    .email-field,
    .new-password-field,
    .confirm-password-field {
        margin-top: 0.5rem !important;
    }
    .lastName-field {
        margin-top: 0.5rem;
    }
    .settings-tab-heading {
        margin-left: 1rem;
    }
    .profile-link,
    .password-link,
    .signature-link {
        padding: 0px 6px !important;
        font-size: 14px;
    }
    .settings-form-container {
        padding: 1.5rem !important;
    }
    .create-btn,
    .uploadImageLabel {
        height: 5.6vh !important;
    }
    .signature-tab {
        margin-left: 0px !important;
    }
    .signCanvas {
        width: 490px;
    }
    .signed-block {
        flex-direction: column;
        gap: 1.2rem;
        justify-content: center;
    }
    .signature-save-btn {
        margin-left: 0px !important;
        margin-bottom: 15px;
    }
    .uploadImage-container {
        justify-content: center;
    }
}

@media (max-width: 414px) {
    .signCanvas {
        width: 360px;
    }
}

@media (max-width: 393px) {
    .signature-modal-header,
    .signature-modal-footer {
        padding: 2px;
    }
    .signature-modal-body {
        padding: 8px;
    }
}

@media (max-width: 375px) {
    .create-btn,
    .uploadImageLabel {
        height: 6.5vh !important;
    }
    .signCanvas {
        width: 340px;
    }
}

@media (max-width: 360px) {
    .profile-link,
    .password-link,
    .signature-link {
        padding: 0px 4px !important;
        font-size: 13px;
    }
    .create-btn,
    .uploadImageLabel {
        width: 12rem;
        height: 6vh !important;
    }
    .signature-modal-header,
    .signature-modal-footer {
        padding: 0px;
    }
    .signCanvas {
        width: 330px;
    }
}

@media (max-width: 280px) {
    .profile-link,
    .password-link,
    .signature-link {
        padding: 0px !important;
        font-size: 10.5px;
    }
    .my-documents-navbar {
        padding: 0px;
    }
    .create-btn,
    .uploadImageLabel {
        width: 7rem !important;
        height: 7vh !important;
        font-size: 10px !important;
    }
    .account-settings {
        margin: 0rem 0.5rem;
    }
    .settings-form-container {
        padding: 0.8rem !important;
    }
    .signature-modal-header,
    .signature-modal-footer {
        padding: 0px;
    }
    .signature-modal-body {
        padding: 5px;
    }
    .signCanvas {
        width: 250px;
    }
    .draw-sign,
    .type-sign {
        font-size: 13.5px;
    }
    .my-signature {
        width: 140px;
    }
}
