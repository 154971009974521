.dashboard-container {
    background-image: url('./../../assets/body-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dropzone-section {
    margin: auto;
    text-align: center;
}

.dropzone {
    width: 50%;
    height: 120px;
    border-radius: 25px;
    padding: 30px;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
}

.file-icon {
    padding: 15px;
    background-color: #ededed;
    border-radius: 100%;
}

.individual-document-counts-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F5F7;
    box-shadow: 0px 4px 3px #00000029;
    border-radius: 24px;
    opacity: 1;
    width: 44% !important;
}

.document-counts-badge {
    background: #E3684E;
    box-shadow: 0px 8px 6px #00000029;
    border-radius: 35px 18px 18px 18px;
    width: 50px;
    height: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.individual-document-type {
    font-size: 17px;
    color: #535353;
}

.individual-document-count {
    font-size: 45px;
    font-weight: bold;
}

.dashboard-image { 
    height: 60vh;
}

.dashboard-section {
    padding: 0;
}

.close-btn,
.share-docs-btn {
    font-size: 14px;
}

/* Media Queries */
@media (max-width: 1280px) {
    .individual-document-height {
        width: 85% !important;
    }
    .dropzone-section {
        width: 65% !important;
    }
}

@media (max-width: 1024px) {
    .dashboard-section {
        padding: 40px 0px;
    }
    .dashboard-image { 
        height: 55vh;
    }
    .individual-document-height {
        width: 75% !important;
    }
    .dropzone-section {
        width: 70% !important;
    }
}

@media (max-width: 912px) {
    .dashboard-image { 
        height: 35vh !important;
    }
    .dropzone-section {
        width: 75% !important;
    }
    .individual-document-height {
        width: 100% !important;
    }
    .documents-counts-info-container {
        width: 100%;
        margin-top: 4rem;
    }
    .file-icon {
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .dashboard-image { 
        height: 30vh !important;
    }
    .dropzone-section {
        width: 80% !important;
    }
    .file-icon {
        margin-bottom: 12px;
    }
    .individual-document-height {
        width: 100% !important;
        gap: 0px !important;
    }
    .individual-document-counts-info {
        width: 85% !important;
    }
    .documents-counts-info-container {
        width: 95%;
        margin-left: 2.5rem;
        margin-top: 2rem;
    }
    .dropzone {
        height: 130px;
    }
}

@media (max-width: 280px) {
    .documents-counts-info-container {
        margin-left: 1.5rem;
    }
    .dropzone {
        height: 150px;
    }
    .close-btn,
    .share-docs-btn {
        font-size: 12px;
    }
    .shared-docs-title {
        font-size: 17px;
    }
}
