.card-title {
    font-weight: 600;
    padding: 1rem;
    margin: 0;
}

.divider-line {
    width: 100%;
    border: 1px solid grey
}

.tab-button {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    gap: 0.8rem;
    border-radius: 3pc;
    color: black;
    background: white;
    border: none;
    box-shadow: 1px black;
}

.buttons-col {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.2rem 0rem 0rem 0rem;
}

.tab-button:hover {
    color: black;
    background-color: white !important;
    box-shadow: 2px 3px 5px 2px #FFC0CB !important;
}

.signature-tab-card-body {
    padding: 1rem 0.5rem;
}

.photo-card-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.camera-tab-button {
    padding: 0.5rem 2rem;
}

.tab-reject-btn {
    background-color: red;
    border: none;
    padding: 0.3rem 2rem;
    border-radius: 2pc;
}

.tab-reject-btn:hover {
    background-color: red;
    border: none;
    padding: 0.3rem 2rem;
    border-radius: 2pc;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.tab-reject-btn:active {
    background-color: red !important;
}

.tab-finish-btn {
    border: none;
    padding: 0.3rem 2rem;
    border-radius: 2pc;
}

.tab-finish-btn:hover {
    border: none;
    padding: 0.3rem 2rem;
    border-radius: 2pc;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}