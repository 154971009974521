.image_icons {
    position: relative;
}

.inactive-small {
    position: absolute;
    top: 11px;
    left: 10px
}

.inactive-icon {
    position: absolute;
    top: 70px;
    left: 73px
}

.active-big {
    margin: 50px;
    margin-top: 40px
}

.active-icon {
    position: absolute;
    top: 28px;
    left: 77px
}

.stepper-main {
    display: flex;
    padding: 1rem;
    align-items: center;
    font-size: 12px
}

.icon-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center
}

.stepper-subtitle {
    width: 85px;
    margin-top: -25px
}

.stepper-line {
    width: 100%;
    height: 0px;
    margin-left: -50px;
    margin-right: -50px
}

.active-line {
    border: 2px solid #358FB7
}

.inactive-line {
    border: 1px solid #000
}

@media screen and (max-width: 450px) {
    .image_icons {
        margin: -60px !important;
        transform: scale(0.6) !important;
    }

    .stepper-subtitle {
        display: none !important;
    }
}

@media screen and (max-width: 580px) {
    .image_icons {
        display: block;
        margin: -40px;
        transform: scale(0.5);
    }
}

@media screen and (max-width: 750px) {
    .image_icons {
        display: block;
        margin: -20px;
        transform: scale(0.8);
    }
}