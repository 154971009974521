.signature-creation-tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-weight: 600;
    color: grey !important
}

.signature-creation-tabs .active {
    color: white !important;
}

.signature-creation-tabs .nav-item button {
    padding-left: 3rem;
    padding-right: 3rem;
    color: grey;
}

.modal-container-top {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.tab-list-item {
    display: flex;
    justify-content: center !important;
}

.signature-tab-footer .modal-footer {
    display: flex;
    justify-content: center;
}

.signature-modal-header-content {
    width: 100%;
    padding-right: 0.6rem;
}

.signature-modal-header-content .nav {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.signature-modal-header-content .nav .nav-item a {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    color: grey;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
}

.modal-container-top-signature .modal-header {
    padding: 1rem 1rem 0rem 0.8rem;
}

.signature-modal-body {
    display: grid;
    align-items: center;
    height: 130px;
    width: 460px;
}