.footer-reset {
    margin-top: 13.5rem;
}

.resetpwd-form {
    background-color: rgb(255, 255, 255) !important;
    width: 27%;
    padding: 52px 46px !important;
    border-radius: 10px;
    margin-top: 1rem;
}

.resetpwd-vector-section {
    display: flex;
    justify-content: space-evenly;
}

.resetpwd-vector img {
    height: 20vw;
    width: 100%;
    margin-top: 4vw;
}

.btnresetpwd {
    padding: 8px 22px !important;
    color: rgba(255, 255, 255, 0.81);
    border: none;
    font-size: 15px;
    border-radius: 15px;
    width: 100%;
}

/* Media Queries */
@media (max-width: 1280px) {
    .resetpwd-form {
        width: 40%;
    }
    .btnresetpwd {
        width: 40%; 
        margin-top: 2rem;
    }
    .login-btn { 
        display: flex;
        justify-content: center;
    }
    .resetpwd-vector img {
        height: 22vw;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .resetpwd-form {
        width: 43%;
        padding: 41px 43px !important;
        margin-top: calc(-9*1vh) !important;
    }
    .resetpwd-vector img {
        height: 21vw;
    }
}

@media screen and (width: 768px) {
    .resetpwd-vector-section {
        margin-top: calc(-4*1.2vh) !important;
    }
    .resetpwd-vector img {
        height: 31vw !important;
    }
    .resetpwd-form {
        padding: 30px 33px !important;
    }
    .btnresetpwd {
        margin-top: 1rem;
    }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
    .resetpwd-vector-section {
        margin-right: 4rem;
        display: flex !important;
        flex-direction: column !important;
        align-items: center;
        gap: 0.3rem;
    }
    .resetpwd-vector img {
        height: 32vw;
    }
    .resetpwd-form {
        width: 34rem;
        padding: 30px 33px !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
    .resetpwd-vector-section {
        margin-top: 2vh;
        margin-right: 3rem;
    }
    .resetpwd-form {
        width: 68%;
        padding: 30px 31px !important;
        border-radius: 10px;
    }
    .resetpwd-vector img {
        display: none;
    }
    .btnresetpwd {
        margin-top: 1rem;
        width: 50%;
    }
}

@media screen and (min-width:320px) and (max-width:414px) {
    .resetpwd-form {
        width: 21.5rem;
        padding: 43px 19px !important;
    }
    .resetpwd-vector-section {
        margin-top: 8vh;
        margin-right: 1rem;
    }
    .resetpwd-vector img {
        display: none;
    }
}

@media screen and (min-width:275px) and (max-width:300px) {
    .resetpwd-vector-section {
        margin-right: 0.5rem !important;
    }
    .resetpwd-form {
        width: 15rem;
        padding: 18px 16px !important;
    }
    .resetpwd-vector img {
        display: none;
    }
    .btnresetpwd {
        width: 60%;
        margin-top: 1rem;
    }
}

@media screen and (width: 375px) {
    .resetpwd-form {
        width: 18rem;
        padding: 12px 16px !important;
    }
    .resetpwd-vector-section {
        margin-top: 5vh;
        margin-right: 1.5rem;
    }
    .resetpwd-vector img {
        display: none;
    }
    .btnresetpwd {
        width: 50%;
        margin-top: 1rem;
    }
}

@media screen and (width: 360px) {
    .resetpwd-form {
        width: 19rem;
        padding: 15px 35px !important;
    }
    .resetpwd-vector-section {
        margin-top: 8vh;
        margin-right: 1rem;
    }
    .resetpwd-vector img {
        display: none;
    }
    .btnresetpwd {
        width: 60%;
        margin-top: 1rem;
    }
    .validation-msg {
        font-size: 12px !important;
    }
}
