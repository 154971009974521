/* .nav-middle-section {
    border-bottom: 2px solid orange;
    margin-left: 120px;
} */

/* tour css */
.reactour__dot {
    border-radius: 0px !important;
  }

  #___reactour > div:first-child {
    opacity: 0.7 !important;
  }
  
  .reactour__helper nav[data-tour-elem='navigation'] .reactour__dot--is-active {
    background-color: #eabd56 !important;
  }
  
  .reactour__helper span[data-tour-elem='badge'] {
    background-color: #eabd56 !important;
    color: #2b2d2c !important;
  }

  .take-tour {
    font-size: 0.8rem;
    padding: 0.3rem;
    border: none;
    border-radius: 0.2rem;
    color: black;
    font-weight: 700;
  }
  
  .bBofGr {
    position: absolute;
    top: 10px !important;
    right: 16px !important;
    width: 10px !important;
    height: 12px !important;
    color: rgb(94, 94, 94);
  }
  
  .reactour__helper--is-open {
    max-width: 375px !important;
  }
  
  .reactour__helper div[data-tour-elem='controls'] {
    justify-content: space-between !important;
  }
  
  .reactour__helper nav[data-tour-elem='navigation'] {
    order: -1 !important;
  }
  
  .reactour__helper button[data-tour-elem='left-arrow'],
  .reactour__helper button[data-tour-elem='right-arrow'],
  .reactour__helper button[data-tour-elem='right-arrow']:active,
  .reactour__helper button[data-tour-elem='right-arrow']:focus {
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: 0px !important;
    border-width: 0px !important;
    outline: 0px !important;
    font-weight: bold !important;
  }
/* tour css end */

.active-nav-item,
.nav-item:hover {
    /* background-color: #6b7797; */
    border-radius: 12px;
}

.dropdown {
    position: relative;
    display: inline-block;
    color: #fff;
    outline: none;
}

.dropdown-content {
    display: none;
    position: absolute;
    left: 0;
    margin-top: 6px;
    font-size: 14px;
    background-color: #f9f9f9;
    color: #000;
    padding: 4px 30px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.username-first-letter {
    background-color: #223C4B;
    height: 35px;
    line-height: 35px;
    width: 35px;
    border-radius: 100%;
}

.dropdown-content a {
    color: black;
    padding: 4px 20px;
    text-decoration: none;
    display: block;
    font-size: 14px;
    border-radius: 5px;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.logout-btn {
    border-radius: 50%;
    color: rgb(200, 200, 200);
    background-color: #022e49cd;
    border: none;
    padding: 10px 18px 10px 18px
}

.menu-drop {
    min-width: fit-content !important;
}

.exit-logo {
    color: red;
    font-size: 15px;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.nav-logo {
    width: 100px;
    height: 70px;
}

/* Media Queries */
@media (max-width: 767px) {
    .profile {
        display: none !important;
    }
}

@media (min-width: 360px) and (max-width: 740px) {
    .nav-container {
        padding-left: 0rem;
    }
}

@media (max-width: 280px) {
    .nav-logo {
        width: 50px;
        height: 50px;
    }
}

.nav-bar-links {
    background-color: transparent !important;
}