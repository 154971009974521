.change-plan-modal-title {
    border-bottom: 1px solid;
    width: 50%;
}

/* Media Queries */
@media screen and (max-width: 1200px) {
    .change-plan-modal-title {
        font-size: 1.8rem;
        font-weight: 500;
    }
}

@media screen and (max-width: 1199px) {
    .change-subscription-plan {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
}

@media screen and (max-width: 991px) {
    .change-plan-modal-title {
        font-size: 1.2rem;
        font-weight: 500;
        width: 75%;
    }
}

@media screen and (max-width: 343px) {
    .change-plan-modal-title {
        font-size: 1rem;
        font-weight: 500;
        width: 75%;
    }
}

@media screen and (max-width: 301px) {
    .change-plan-modal-sub-description {
        font-size: 1rem !important;
    }
}