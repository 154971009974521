.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.8);
}

.custom-modal-header {
    border-bottom: 0px;
}

.custom-modal-footer {
    border-top: 0px;
}

.custom-modal-title {
    font-size: 2rem;
}

.custom-modal-content {
    border: 0;
}

.cross-icon {
    width: 4rem;
}

.back-arrow-icon {
    width: 14px;
}

/* Media Queries */

@media (max-width: 430px) {
    .custom-modal-title {
        font-size: 1.5rem;
    }

    .modal-description {
        font-size: 1rem !important;
    }
}

@media (max-width: 315px) {
    .custom-modal-title {
        font-size: 1.2rem;
    }

    .modal-description {
        font-size: 1rem !important;
    }
}