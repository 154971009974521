.rejection-modal-title {
    padding: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
}

.rejection-modal-title-div {
    color: white !important;
}

.reject-textarea-box {
    border: 2px dashed #b6b6b6;
    outline: none;
    margin: 6% 9%;
    width: 81%;
    border-radius: 10px;
    height: 145px;
    padding: 14px 10px;
}

.reject-modal-buttons {
    border: none;
    background-color: grey;
}

.reject-modal-buttons:hover {
    border: none;
    background-color: grey;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.reject-modal-buttons:active {
    border: none !important;
    background-color: grey !important;
}

.reject-modal-buttons-reject {
    border: none;
    background-color: red;
    color: white;
}

.reject-modal-buttons-reject:hover {
    border: none;
    background-color: red;
    color: white;
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.reject-modal-buttons-reject:active {
    border: none;
    background-color: red !important;
    color: white;
}

.reject-modal-footer {
    display: flex;
    justify-content: center;
}

.reject-modal-buttons:active {
    background-color: green;
}