.custom-model-header {
    text-align: center;
    color: white !important;
    width: 100%;
}

.otp-input-container div {
    display: flex;
    justify-content: center;
}

.modal-container-top {
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
}

.remove-borders {
    border: none;
}