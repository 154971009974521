.subscriptions-border {
    border-bottom: 1px solid rgba(158, 158, 158, 0.6);
}

.storage-bar {
    background-color: rgb(72, 197, 72);
}

.progress {
    height: 12px;
}

.active {
    border-color: rgb(72, 197, 72);
}

.pill {
    width: 9px;
    height: 9px;
    border-radius: 50%;
}

.popover-header {
    border-bottom: none;
    background-color: #F8F8F8;
}

.popover-body {
    background-color: #F8F8F8;
}

.select-btn {
    font-size: 12px;
}

.storage-div {
    background-color: #F0F0F0;
}

.storage-div:hover {
    background-color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.storage-div.highlighted {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    background-color: white;
}

/* .cancel-subscription-btn:hover {
    color: rgb(218, 24, 24) !important;
    background-color: #fff !important;
    border-color: rgb(218, 24, 24);
} */

/* Media Queries */

@media (max-width: 912px) {
    .subscription-btns {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 892px) {
    .responsive-popover {
        width: 10rem !important;
    }
    .storage-div {
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }
}

@media (max-width: 767px) {
    .progress,
    .storage-progress-sec {
        width: 100% !important;
    }
    .storage-btn {
        margin-bottom: 1rem;
    }
    .basic-plan-sec {
        margin-left: 0px !important;
    }
    .auto-debit-btn {
        margin: 1rem 0rem;
    }
    .progress-bars-sec {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.form-switch .auto-debit-switch {
    border-radius: 10px !important;
}