.templates-custom-card {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 0.3rem;
    padding: 0.3rem;
}

.template-gallery-card {
    width: 150px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 0.3rem;
}

.template-gallery-text {
    padding: 0.5rem;
    text-align: center;
}

.upload-document-button {
    text-align: center;
    border: none;
}

.template-categories-modal-button {
    color: #fff;
    font-size: 1.2rem;
    transition: transform 500ms;
    cursor: pointer
}

.template-categories-modal-button:hover {
    transform: scale(1.15);
}

.template-image {
    height: 150px;
}

.template-update-modal .modal-dialog {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.template-update-modal .modal-content {
    width: 1170px;
}

.form-check-input[type=checkbox] {
    border-radius: 0 !important;
}

/* TemplateDocumentCreationModal */
.template-document-creation-modal .modal-dialog {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.template-document-creation-modal .modal-content {
    width: fit-content;
}

.template-image-row {
    height: 10rem;
    object-fit: contain;
    margin: 0.5rem;
}

.card-template-row {
    grid-template-columns: 1fr 3fr 3fr;
}

.buttons-div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    align-items: end;
    justify-content: center;
}

.buttons-div button {
    width: 8rem;
}

.action-btn {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.btns-div {
    display: flex;
    gap: 0.5rem;
    justify-content: end;
}

.template-col {
    padding: 1rem;
    gap: 2rem;
    justify-content: center;
}

.card-template-row-list {
    width: 10rem !important;
    height: 15rem;
}

.card-template-row img {
    height: 10rem;
    object-fit: contain;
    margin: 0.5rem;
}

.card-template-row-list:hover {
    transform: scale(1.05);
    transition: transform 200ms;
}

.card-title-row {
    display: flex;
    align-items: end;
    background-image: linear-gradient(to bottom right, #00000000, #00000031);
    padding: 0.5rem;
}

.template-header {
    display: grid;
    grid-template-columns: 4fr 1fr 0.5fr 0.5fr;
    padding: 15px 10px;
    align-items: center;
    border-radius: 15px;
    justify-items: center;
}

.templates-type-dropdown button {
    background: none;
    border: none
}

.templates-type-dropdown button:hover {
    background: none;
    border: none
}

.templates-type-dropdown button:active {
    background: none;
    border: none
}

.templates-type-dropdown .btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: none;
    --bs-btn-border-color: none;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: none;
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: none;
    --bs-btn-active-border-color: none;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;
}

.search-template {
    border-radius: 0px 15px 15px 0px;
    padding: 2px;
    padding-left: 4px;
    width: 20rem;
    border: none;
    border-left: 1px solid grey;
    outline: none;
}

.template-list-type {
    display: flex;
    justify-content: center;
    gap: 4px;
    padding: 5px 20px;
    background-color: white;
    border-radius: 15px;
    width: 5rem;
    cursor: pointer;
}

.add-icon {
    display: flex;
    padding: 8px;
    background-color: white;
    width: 2.5rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.add-icon:hover {
    box-shadow: 3px 5px 5px 2px #00000057;
    transform: scale(1.1);
    transition: 500ms transform;
}

.dropwn-down-div {
    width: 100%;
    display: flex;
    align-items: center;
}

.search-icon-div {
    background-color: #fff;
    width: 2rem;
    padding: 2px;
    border-radius: 15px 0px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-div {
    display: flex;
}

.create-template-list {
    border: 1px dashed grey;
}

.template-list-create-icon {
    display: flex;
    background-color: red;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    width: 2.8rem;
}

.create-template-div {
    font-weight: 600;
    font-size: 15px;
    width: 8rem;
}

.template-creation-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
}

.template-card {
    width: 15rem;
    transition: transform 500ms;
}

.template-card:hover {
    transform: scale(1.1);
    box-shadow: 3px 5px 20px 2px #0000002d;
}

.template-card:not(:hover) {
    transform: scale(1);
}

.template-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-tab-body {
    padding: 1rem;
    border: 1px solid grey;
    margin: 1rem 0;
    border-radius: 1rem;
}

.existing-template-card {
    transition: transform 500ms;
}

.template-title {
    word-wrap: normal;
    overflow: hidden;
}

.template-image-div {
    transition: transform 500ms;
}

.existing-template-card:hover {
    .template-title {
        display: none;
    }

    .template-action-buttons {
        display: grid;
    }

    .template-image-div {
        transform: scale(1.1);
    }

    transform: scale(1.05);
}

.template-action-buttons {
    display: none;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    cursor: pointer;
    font-weight: 600;
}

.template-delete-icon {
    color: red;
    transition: transform 500ms;
}

.template-delete-icon:hover {
    transform: scale(1.3);
}

.template-delete-icon:not(:hover) {
    transform: scale(1);
}

.template-download-icon {
    color: green;
    transition: transform 500ms;
}

.template-download-icon:hover {
    transform: scale(1.3);
}

.template-download-icon:not(:hover) {
    transform: scale(1);
}

.template-edit-icon {
    color: rgb(0, 0, 85);
    transition: transform 500ms;
}

.template-edit-icon:hover {
    transform: scale(1.3);
}

.template-edit-icon:not(:hover) {
    transform: scale(1);
}

.template-image-div {
    display: flex;
    justify-content: center;
}

.template-card-footer {
    background-color: none;
    text-align: center;
}

.template-image {
    height: 12rem;
}

.template-title-feild {
    display: flex;
    gap: 10px;
    align-items: center;
}

.template-row-data {
    margin: 0.5rem;
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid grey;
    display: flex;
    justify-content: space-between;
    transition: transform 500ms;
}

.template-row-data:hover {
    transform: scale(1.01);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.template-row-actions {
    display: grid;
    width: 25rem;
    grid-template-columns: 1fr 1fr 1fr;
}

.template-icon-divider {
    width: 1px;
    border: 1px solid rgb(207, 207, 207);
}

.row-icon-div {
    display: flex;
    border-radius: 2rem;
    padding: 0.5rem 0.8rem;
    justify-content: space-between;
    width: 6rem;
    font-size: 14px;
    cursor: pointer;
}

.template-row-aciton-icons {
    display: flex;
    padding-right: 2rem;
    gap: 2rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.2rem;
}

.template-row-aciton-icons>* {
    transition: transform 500ms;
}

.template-row-aciton-icons>*:hover {
    transform: scale(1.3);
}

.template-row-aciton-icons>*:not(:hover) {
    transform: scale(1);
}

.zoom-transistion {
    transition: transform 300ms;
}

.scale-transistion {
    transform: scale(1.05);
}

@media screen and (max-width: 770px) {
    .template-header {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
    }

    .search-template {
        width: calc(100%);
    }

    .template-row-aciton-icons {
        padding-right: 0.5rem;
        gap: 0.7rem;
    }

    .template-title-feild {
        overflow: hidden;
        width: 15rem;
    }
}

@media screen and (max-width: 500px) {
    .template-title-feild {
        overflow: hidden;
        /* width: 20rem; */
    }

    .template-row-data {
        flex-direction: column;
        gap: 1rem;
    }

    .template-row-aciton-icons {
        justify-content: center;
    }
}