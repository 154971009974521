/* Media Queries */
@media (max-width: 393px) {
    .choose-plan-btn {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        font-size: 14px;
    }
}

@media (max-width: 280px) {
    .choose-plan-btn {
        padding-left: 0.9rem !important;
        padding-right: 0.9rem !important;
        font-size: 12px;
        margin-left: 10px;
    }
}