.table {
    /* width: 50%; */
    margin: 0 auto !important;
}
.table>:not(caption)>*>*{
    border-bottom: none;
    text-align: center;
}
.signers-body {
    background-color: rgba(227, 227, 227, 0.386);
    padding: 40px;
    border-radius: 20px;
}
.body-data {
    background-color: rgba(255, 255, 255, 0.944);
}