.header-normal {
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100% !important;
}

.cursor-pointer,
.pointer {
  cursor: pointer !important;
}

.site-primary-color {
  color: var(--site-primary-color);
}

.site-secondary-color {
  color: var(--site-secondary-color);
}

.bold {
  font-weight: bold;
}

.visibility-hidden {
  visibility: hidden;
}

.no-border {
  border: none;
}

.align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.flex-1 {
  flex: 1;
}

.text-align-left {
  text-align: left;
}

.w-65 {
  width: 65%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-color { 
  color: #114b65;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  content: "✔";
  margin-right: 5px;
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  content: "✖";
  margin-right: 5px;
}

/* Templates */
.field-name {
  font-size: 1.1rem;
}